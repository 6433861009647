import { useState, useEffect } from 'react';
import { Box, Button, Grid, Modal, Snackbar, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { format } from 'date-fns';
import UserForm from './UserForm';
import SelectFilter from '../Components/UI/SelectFilter';
import RegistersTable from '../Components/UI/RegistersTable';
import BackdropLoader from '../Components/UI/BackdropLoader';
import { Alert } from '../Components/UI/Common';
import { apiGatwayCall } from '../Components/Utils/utils';
import getEnum from '../Components/Utils/Enum';
import { useAuthContext } from '../Components/Auth/authContext';
import { userList, utils } from '../Data';
import '../Styles/Products.css';

const defaultUser = {
  user_id: '',
  lilly_id: '',
  user_name: '',
  user_email: '',
  user_role: 0,
  status: 1,
  createdby: 1,
  updatedby: 1,
};

export default function Users() {
  const [userRole, setUserRole] = useState(0);
  const [userRoles, setUserRoles] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [isUpdate, setIsUpdate] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alert, setAlert] = useState({ message: '', type: '' });
  const [newUser, setUser] = useState(defaultUser);
  const [modalAction, setModalAction] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [isEditSearch, setIsEditSearch] = useState(false);
  const { user } = useAuthContext();
  const selectData = ['role'];

  const handleAlertClick = (message, type) => {
    setAlert({
      message,
      type,
    });
    setAlertOpen(true);
  };

  // GET all User roles from user table
  useEffect(() => {
    async function getData() {
      const roleData = await getEnum(user.token, selectData);

      const roles = roleData.role.map((r) => ({
        id: r.role_id,
        name: r.name,
      }));
      roles.splice(0, 0, { id: 0, name: 'All Roles' });

      setUserRoles(roles);
      setIsLoading(false);
    }
    getData();
  }, []);

  // GET request to fetch User list
  useEffect(() => {
    async function getUser() {
      await apiGatwayCall(
        user.token,
        { roleid: userRole, search: search },
        'getuserList'
      )
        .then((response) => {
          const { data, message, statusCode } = response.data;
          if (statusCode === 200) {
            const rows = data.map((u) => ({
              Id: u.user_id,
              data: [
                u.name,
                u.lilly_id,
                u.role_id === 1 ? 'Admin' : 'Operator',
              ],
            }));
            setTableRows(rows);
          } else if (statusCode === 404) {
            setTableRows([]);
          } else {
            handleAlertClick(message, 'error');
          }
          setIsSearching(false);
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    if (isUpdate) {
      getUser();
      setIsUpdate(false);
    }
  }, [isUpdate]);

  // GET request to fetch a User
  const handleEditUser = (userId) => {
    setIsEditSearch(true);
    async function getData() {
      await apiGatwayCall(user.token, { id: userId }, 'getuser')
        .then((response) => {
          const { data, message, statusCode } = response.data;
          if (statusCode === 200) {
            setModalAction('EDIT');
            setUser({
              user_id: data[0].user_id,
              lilly_id: data[0].lilly_id,
              user_name: data[0].name,
              user_email: data[0].user_email,
              user_role: data[0].role_id,
              status: data[0].active === true ? 1 : 2,
            });
            setIsEditSearch(false);
            setOpen(true);
          } else {
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    getData();
    setIsUpdate(true);
  };

  // PUT request to delete a User
  const handleDeleteUser = (delUserId) => {
    const delUserData = {
      id: delUserId,
      updatedBy: user.user_id,
      updatedAt: format(new Date(), utils.dateFormat),
    };
    async function deleteData() {
      await apiGatwayCall(user.token, delUserData, 'deleteuser')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setIsUpdate(true);
            handleAlertClick('User removed successfully.', 'success');
          } else {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    deleteData();
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleModalOpen = () => {
    setOpen(true);
    setModalAction('ADD');
    setUser(defaultUser);
  };

  const handleModalClose = (message, alertType) => {
    setOpen(false);
    if (typeof message === 'string') {
      handleAlertClick(message, alertType);
      setIsUpdate(true);
    }
  };

  const handleClickSearch = (e) => {
    if (e.target.id === 'search') {
      setIsSearching(true);
      setIsUpdate(true);
    } else if (e.target.id === 'clear') {
      setUserRole(0);
      setSearch('');
      setIsUpdate(false);
    }
  };

  const handleChangeUserRole = (value) => {
    setUserRole(value);
  };

  const handleSearchField = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div>
      {isLoading ? (
        <BackdropLoader show={isLoading} />
      ) : (
        <div>
          <BackdropLoader show={isEditSearch} />
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleAlertClose}
          >
            <Alert onClose={handleAlertClose} severity={alert.type}>
              {alert.message}
            </Alert>
          </Snackbar>
          <Grid container item xs={12}>
            <h2 className='product-title'>User List</h2>
          </Grid>

          <Grid container direction='row' spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <label className='form-label' htmlFor='userSearch'>
                Search Term
              </label>
              <TextField
                sx={{ width: '100%' }}
                id='userSearch'
                type='text'
                value={search}
                onChange={handleSearchField}
                placeholder='Search by Lilly ID or Lilly User Name'
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <label className='form-label' htmlFor='roleSelect'>
                User Role
              </label>
              <SelectFilter
                name='roleSelect'
                id='roleSelect'
                selected={userRole}
                getSelected={handleChangeUserRole}
                minWidth='100%'
                optionList={userRoles}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={5} lg={3}>
              <Button
                id='search'
                variant='contained'
                className='search-btn'
                startIcon={<SearchIcon />}
                onClick={handleClickSearch}
              >
                Search
              </Button>
              <Button
                id='clear'
                variant='contained'
                className='search-btn'
                startIcon={<ClearIcon />}
                onClick={handleClickSearch}
              >
                Clear Search
              </Button>
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={6}
              md={12}
              lg={4}
              direction='row'
              justifyContent='flex-end'
            >
              <Button
                color='success'
                variant='contained'
                className='form-btn'
                onClick={handleModalOpen}
                startIcon={<AddIcon />}
              >
                Add New User
              </Button>
            </Grid>
          </Grid>
          <RegistersTable
            searching={isSearching}
            register={userList}
            rows={tableRows}
            handleEdit={handleEditUser}
            handleDelete={handleDeleteUser}
          />

          <Modal
            open={open}
            onClose={(_, reason) => {
              if (reason !== 'backdropClick') {
                handleModalClose();
              }
            }}
            aria-labelledby='parent-modal-title'
            aria-describedby='parent-modal-description'
          >
            <div>
              <Box className='product_modal_box'>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <h4 className='modaltitle'>
                    {modalAction === 'ADD' ? 'Create New User' : 'Edit User'}
                  </h4>
                  <Button onClick={handleModalClose}>
                    <ClearIcon />
                  </Button>
                </div>

                <UserForm
                  content={userList}
                  editUser={newUser}
                  modalAction={modalAction}
                  handleClose={handleModalClose}
                  roles={userRoles}
                />
              </Box>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}
