import { makeStyles } from '@mui/styles';
import { AppBar, Toolbar, Typography } from '@mui/material';
import '../../Styles/Navbar.css';
import logo from '../../Images/lilly_logo.png';

const useStyles = makeStyles({
  bar: {
    color: 'white',
    fontSize: '30px',
    height: '47px',
  },
});

export default function AuthNav() {
  const classes = useStyles();

  return (
    <AppBar position='static' sx={{ backgroundColor: '#d52b1e' }}>
      <Toolbar>
        <img alt='' className='logo-link' src={logo} />
        <div className={classes.bar}>|</div>
        <Typography variant='h6' component='div' sx={{ flexGrow: 1, pl: 2 }}>
          SEE Donation App
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
