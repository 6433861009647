import { useState } from 'react';
import { Button, Snackbar, Tooltip, Zoom } from '@mui/material';
import {
  Alert,
} from '../Components/UI/Common';
import PrintIcon from '@mui/icons-material/PrintRounded';
import { format } from 'date-fns';
import { reportButtons, utils } from '../Data';
import { reports } from '../Pages/Reports';
import { useAuthContext } from './Auth/authContext';
import '../Styles/Products.css';

// This component is used to create reports and letters
function ReportButtons(props) {
  const { user } = useAuthContext();
  const [alert, setAlert] = useState({ message: '', type: '' });
  const [alertOpen, setAlertOpen] = useState(false);
  let { filter } = props;

  const handleAlertClick = (message, type) => {
    setAlert({
      message,
      type,
    });
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleOnClick = (e) => {
    handleAlertClick('Opening Report...', 'info');
    const reportName = e.target.name;
    filter.updatedAt = format(new Date(), utils.reportDateFormat);
    reports(user.token, reportName, filter);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={4000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert.type}>
          {alert.message}
        </Alert>
      </Snackbar>
      {reportButtons.map((btn) => (
          <Tooltip
            key={btn.id}
            TransitionComponent={Zoom}
            arrow
            placement='top'
            title={btn.tooltipTitle}
            enterTouchDelay={0}
          >
            <Button
              key={btn.id}
              variant='contained'
              name={btn.btnName}
              className='dashboard-btn'
              onClick={handleOnClick}
              sx={{ backgroundColor: btn.bgColor }}
              startIcon={<PrintIcon />}
            >
              {btn.btnName}
            </Button>
          </Tooltip>
      ))}
    </>
  );
};

export default ReportButtons;