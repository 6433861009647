import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  Tooltip,
  Zoom,
  useMediaQuery,
} from '@mui/material';
import SelectFilter from '../Components/UI/SelectFilter';
import { Asterisk } from '../Components/UI/Common';
import '../Styles/Products.css';

const useStyles = makeStyles(() => ({
  textField: {
    width: '98% !important',
  },
  selectPadding: {
    paddingLeft: '24px !important',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  fontStyle: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    paddingTop: '10px',
  },
}));

const defaultProduct = {
  productId: '',
  countryId: 1,
  enumCurrencyId: 166,
  code: '',
  name: '',
  brand: '',
  price: 0,
  dosage: '',
  typeOfApplication: '',
  localTypeOfApplication: '',
  description: '',
};

function Productform(props) {
  const classes = useStyles();
  const match = useMediaQuery('(max-width: 600px)');
  const {
    editProduct,
    enumCurrency,
    countries,
    getProductData,
    updateProductData,
    handleClose,
  } = props;
  const [product, setProduct] = useState(defaultProduct);
  const labelAlign = match ? 'left' : 'right';

  const handleChangeSelect = (value, name) => {
    setProduct((prevState) => {
      const field = prevState;
      field[name] = value;
      return {
        ...field,
      };
    });
  };

  const handleOnChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setProduct((prevState) => {
      const field = prevState;
      field[name] = value;
      return {
        ...field,
      };
    });
  };

  useEffect(() => {
    setProduct({ ...editProduct });
  }, []);

  const handleClickSave = () => {
    const prdData = {
      productId: product.productId,
      countryId: product.countryId,
      enumCurrencyId: product.enumCurrencyId,
      code: product.code,
      name: product.name,
      brand: product.brand,
      price: parseFloat(product.price),
      dosage: product.dosage,
      typeOfApplication: product.typeOfApplication,
      localTypeOfApplication: product.localTypeOfApplication,
      description: product.description,
    };
    if (
      product.productId === '' ||
      editProduct.countryId !== prdData.countryId
    ) {
      getProductData(prdData);
    } else {
      updateProductData(prdData);
    }
    handleClose();
  };

  function SubmitButton() {
    if (product.name.length >= 3 && product.code && product.price) {
      return (
        <Button onClick={handleClickSave} variant='contained'>
          Save Changes
        </Button>
      );
    } else {
      return (
        <Tooltip
          TransitionComponent={Zoom}
          arrow
          placement='top'
          title='Fill mandatory fields to enable'
          enterTouchDelay={0}
        >
          <span>
            <Button variant='contained' disabled>
              Save Changes
            </Button>
          </span>
        </Tooltip>
      );
    }
  }

  return (
    <div>
      <Box
        component='form'
        sx={{
          '& .MuiTextField-root': { m: 1 },
          p: 2,
        }}
        noValidate
        autoComplete='off'
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={4} md={4} lg={4} alignItems='center'>
            <Typography
              align={labelAlign}
              variant='subtitle1'
              classes={{ root: classes.fontStyle }}
            >
              Country
              <Asterisk>*</Asterisk>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            lg={6}
            classes={{ root: classes.selectPadding }}
          >
            <SelectFilter
              minWidth='100%'
              optionList={countries}
              name='countryId'
              disabled={!!product.isDisabled}
              selected={product.countryId}
              getSelected={handleChangeSelect}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4} alignItems='center'>
            <Typography
              align={labelAlign}
              variant='subtitle1'
              classes={{ root: classes.fontStyle }}
            >
              Name
              <Asterisk>*</Asterisk>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={6}>
            <TextField
              id='name'
              size='small'
              classes={{ root: classes.textField }}
              name='name'
              value={product.name}
              onChange={handleOnChange}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4} alignItems='center'>
            <Typography
              align={labelAlign}
              variant='subtitle1'
              classes={{ root: classes.fontStyle }}
            >
              Brand
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={6}>
            <TextField
              id='brand'
              size='small'
              name='brand'
              classes={{ root: classes.textField }}
              value={product.brand}
              onChange={handleOnChange}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4} alignItems='center'>
            <Typography
              align={labelAlign}
              variant='subtitle1'
              classes={{ root: classes.fontStyle }}
            >
              Product Code
              <Asterisk>*</Asterisk>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={6}>
            <TextField
              id='code'
              size='small'
              name='code'
              classes={{ root: classes.textField }}
              value={product.code}
              onChange={handleOnChange}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4} alignItems='center'>
            <Typography
              align={labelAlign}
              variant='subtitle1'
              classes={{ root: classes.fontStyle }}
            >
              Price
              <Asterisk>*</Asterisk>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={6}>
            <div className='price_div'>
              <TextField
                id='price'
                size='small'
                name='price'
                value={product.price}
                onChange={handleOnChange}
              />

              <SelectFilter
                minWidth='50%'
                disabled
                optionList={enumCurrency}
                selected={
                  product.enumCurrencyId !== '' ? product.enumCurrencyId : 166
                }
                getSelected={handleChangeSelect}
                id='defaultCurrency'
                name='defaultCurrency'
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4} alignItems='center'>
            <Typography
              align={labelAlign}
              variant='subtitle1'
              classes={{ root: classes.fontStyle }}
            >
              Dosage
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={6}>
            <TextField
              id='dosage'
              size='small'
              name='dosage'
              classes={{ root: classes.textField }}
              value={product.dosage}
              onChange={handleOnChange}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4} alignItems='center'>
            <Typography
              align={labelAlign}
              variant='subtitle1'
              classes={{ root: classes.fontStyle }}
            >
              Application Type
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={6}>
            <TextField
              id='typeOfApplication'
              size='small'
              name='typeOfApplication'
              classes={{ root: classes.textField }}
              value={product.typeOfApplication}
              onChange={handleOnChange}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4} alignItems='center'>
            <Typography
              align={labelAlign}
              variant='subtitle1'
              classes={{ root: classes.fontStyle }}
            >
              Local Application Type Name
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={6}>
            <TextField
              id='localTypeOfApplication'
              size='small'
              name='localTypeOfApplication'
              classes={{ root: classes.textField }}
              value={product.localTypeOfApplication}
              onChange={handleOnChange}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4} alignItems='center'>
            <Typography
              align={labelAlign}
              variant='subtitle1'
              classes={{ root: classes.fontStyle }}
            >
              Description
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={6}>
            <TextField
              id='description'
              size='small'
              name='description'
              classes={{ root: classes.textField }}
              value={product.description}
              onChange={handleOnChange}
            />
          </Grid>
        </Grid>
      </Box>
      <div className='buttondiv_productform'>
        <Button variant='outlined' onClick={handleClose}>
          Cancel
        </Button>
        <SubmitButton />
      </div>
    </div>
  );
}

Productform.propTypes = {
  editProduct: PropTypes.instanceOf(Object),
  enumCurrency: PropTypes.instanceOf(Object).isRequired,
  countries: PropTypes.instanceOf(Array).isRequired,
  handleClose: PropTypes.func.isRequired,
  getProductData: PropTypes.func.isRequired,
  updateProductData: PropTypes.func,
};

Productform.defaultProps = {
  editProduct: {},
  updateProductData: () => {},
};

export default Productform;
