import { Paper, Typography, Box, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import '../../Styles/Navbar.css';
import AuthNav from './authNav';
import authProvider from './authProvider';

export default function Landing() {
  const history = useHistory();

  const loginAttmept = async () => {
    const logged = await authProvider.isLoggedIn();
    if(logged) history.replace('/Dashboard');
    else await authProvider.login();
  };

  return (
    <div>
      <AuthNav />
      <Paper
        elevation={3}
        sx={{
          margin: '5%',
          padding: '5%',
        }}
      >
        <Typography variant='h3' gutterBottom component='div'>
          Welcome
        </Typography>
        <Typography variant='h4' gutterBottom component='div'>
          To the SEE Donation Management Tool
        </Typography>
        <Box
          sx={{
            marginTop: '5%',
          }}
        >
          <Typography variant='h5' gutterBottom component='div'>
            Getting Started
          </Typography>
          <Typography variant='h6' gutterBottom component='div'>
            For newly registered users, to start using donation manager, an
            application administrator is supposed to associate you with user
            roles and then you may login. And for existing users, proceed to
            Login directly.
          </Typography>
          <Button variant='contained' onClick={loginAttmept}>
            Login through Lilly SSO
          </Button>
        </Box>
      </Paper>
    </div>
  );
}
