import { apiGatwayCall } from '../Components/Utils/utils';

// Converts base64 to PDF
async function reports(_token, reportName, filter) {
  if (reportName !== 'Q Report' && reportName !== 'HUB Excel') {
    await apiGatwayCall(_token, { reportName, filter }, 'reports').then(
      async (response) => {
        const { data } = response;
        const bytes = window.atob(data.base64data);
        let byteArray = new Array(bytes.length);
        for (let i = 0; i < bytes.length; i += 1) {
          byteArray[i] = bytes.charCodeAt(i);
        }
        const bytesArray = new Uint8Array(byteArray);
        const url = URL.createObjectURL(
          new Blob([bytesArray], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    );
  } else {
    const result = await apiGatwayCall(
      _token,
      { reportName, filter },
      'reports'
    );
    const myBuffer = Buffer.from(result.data, 'base64');
    const url = window.URL.createObjectURL(new Blob([myBuffer]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      reportName === 'HUB Excel' ? 'HUB.xlsx' : 'QReport.xlsx'
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

async function printLetters(_token, reportName, requestId) {
  const response = await apiGatwayCall(
    _token,
    { reportName, requestId },
    'reports'
  );
  const { data } = response;
  if (data.message != null) {
    return data.message;
  } else {
    const bytes = atob(data.base64data);
    let byteArray = new Array(bytes.length);
    for (let i = 0; i < bytes.length; i += 1) {
      byteArray[i] = bytes.charCodeAt(i);
    }
    const bytesArray = new Uint8Array(byteArray);
    const url = URL.createObjectURL(
      new Blob([bytesArray], { type: 'application/pdf' })
    );
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return '';
  }
}

export { reports, printLetters };
