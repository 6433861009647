import './App.css';
import { Route, Switch } from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import CreateDonationRequest from './Pages/CreateDonationRequest';
import Products from './Pages/Products';
import HCOs from './Pages/HCOs';
import Distributors from './Pages/Distributors';
import Alarm from './Pages/Alarm';
import CurrencyList from './Pages/CurrencyList';
import Users from './Pages/Users';
import Landing from './Components/Auth/landing';
import Logout from './Components/Auth/logout';
import PrivateRoute from './privateRoute';
import AccessDenied from './Components/Auth/accessDenied';
import { AppAuthProvider } from '../src/Components/Auth/authContext';
import authProvider from '../src/Components/Auth/authProvider';

function App() {
  return (
    <Switch>
      <Route exact path={['/', '/login']} component={Landing} />
      <Route exact path='/accessDenied' component={AccessDenied} />
      <Route exact path='/logout' component={Logout} />
      <AppAuthProvider auth={authProvider}>
        <PrivateRoute exact path='/auth/callback/' component={Dashboard} />
        <PrivateRoute exact path='/Dashboard' component={Dashboard} />
        <PrivateRoute
          exact
          path='/createdonation'
          component={CreateDonationRequest}
        />
        <PrivateRoute exact path='/registers/Products' component={Products} />
        <PrivateRoute exact path='/registers/HCOs' component={HCOs} />
        <PrivateRoute
          exact
          path='/registers/Distributors'
          component={Distributors}
        />
        <PrivateRoute exact path='/settings/Alarm' component={Alarm} />
        <PrivateRoute
          exact
          path='/settings/CurrencyList'
          component={CurrencyList}
        />
        <PrivateRoute exact path='/settings/AccountMgmt' component={Users} />
      </AppAuthProvider>
    </Switch>
  );
}

export default App;
