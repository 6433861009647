import { useState, createContext, useContext, useEffect } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { apiGatwayCall } from '../Utils/utils';

const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

const getRemaningTime = (expirationDate) => {
  const currentTime = new Date().getTime();
  const expirationTime = new Date(expirationDate).getTime();
  const remaningTime = expirationTime - currentTime;
  return remaningTime;
};

export function AppAuthProvider(props) {
  const { auth, children } = props;
  const history = useHistory();
  const [user, setUser] = useState({});
  const [wait, setWait] = useState(true);
  const [open, setOpen] = useState(false);
  const [isLogged, setIsLogged] = useState(false);

  const login = async () => {
    auth.login();
  };

  const isLoggedIn = async () => auth.isLoggedIn();

  const logout = async () => {
    auth.signout();
    Cookies.remove('user');
    Cookies.remove('expirationDate');
  };

  const handleOpen = () => {
    setWait(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    logout();
  };

  const relogin = () => {
    setOpen(false);
    Cookies.remove('user');
    Cookies.remove('expirationDate');
    window.location.reload();
  };

  async function GetFedUser() {
    const token = await auth.getGatewayAccessToken();
    if (token && token.accessToken !== undefined) {
      await apiGatwayCall(
        token.accessToken,
        { token: token.accessToken },
        'getFedrateUser'
      )
        .then((response) => {
          const { data, statusCode } = response.data;
          const fetchedUserFromDB = data;
          if (statusCode === 0) {
            setWait(false);
            setIsLogged(false);
            history.replace('/accessDenied');
          } else if (fetchedUserFromDB.status === 1) {
            if (fetchedUserFromDB.user_role === 1) {
              fetchedUserFromDB.role = 'ADMIN';
            } else if (fetchedUserFromDB.user_role === 2) {
              fetchedUserFromDB.role = 'OPERATOR';
            }
            Cookies.set('user', JSON.stringify(fetchedUserFromDB));
            Cookies.set('expirationDate', token.expiresOn);
            const remaningTime = getRemaningTime(token.expiresOn);
            setTimeout(handleOpen, remaningTime);
            setUser(fetchedUserFromDB);
            setWait(false);
            setIsLogged(true);
          }
        })
        .catch(() => {
          history.replace('/accessDenied');
        });
    }
  }

  useEffect(async () => {
    const userDetails = Cookies.get('user');
    const expirationDate = Cookies.get('expirationDate');
    if (userDetails) {
      const cookieUser = JSON.parse(userDetails);
      const remaningTime = getRemaningTime(expirationDate);
      setTimeout(handleOpen, remaningTime);
      setUser(cookieUser);
      setWait(false);
      setIsLogged(true);
    } else {
      auth.handlePromiseRedirectCallback(async () => {
        await GetFedUser();
      });
      await GetFedUser();
    }
  }, []);

  return (
    <div>
      <AuthContext.Provider
        value={{
          login,
          logout,
          isLoggedIn,
          user,
          wait,
          isLogged,
          GetFedUser,
        }}
      >
        {children}
      </AuthContext.Provider>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth='xs'
        onBackdropClick={handleOpen}
        open={open}
        onClose={handleOpen}
      >
        <DialogTitle>Session Timed Out!</DialogTitle>
        <DialogContent dividers>
          Your session has expired, please click on &rsquo;OK&rsquo; to
          continue.
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='contained' onClick={relogin}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AuthContext;
