import { useState, useEffect } from 'react';
import { Box, Button, Grid, Modal, Snackbar } from '@mui/material';
import '../Styles/Products.css';
import { format } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import Alarmform from './Alarmform';
import SelectFilter from '../Components/UI/SelectFilter';
import { alarmData, utils } from '../Data';
import RegistersTable from '../Components/UI/RegistersTable';
import BackdropLoader from '../Components/UI/BackdropLoader';
import { Alert } from '../Components/UI/Common';
import getEnum from '../Components/Utils/Enum';
import { getAll, apiGatwayCall } from '../Components/Utils/utils';
import { useAuthContext } from '../Components/Auth/authContext';

const defaultSearchFilter = {
  country: 0,
  HCO: 0,
  product: 0,
};

const alarmDefault = {
  country: 0,
  name: '',
  HCO: 0,
  periodId: 1,
  product: 0,
  amount: '',
  alarmId: 0,
};
const getTableData = ['organization', 'product'];
const getEnumData = ['enum_alarm_period', 'country'];

export default function Alarm() {
  const [tableRows, setTableRows] = useState([]);
  const [isUpdate, setIsUpdate] = useState(true);
  const [isReset, setIsReset] = useState(true);
  const [staticData, setStaticData] = useState({});
  const [open, setOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState({
    country: 0,
    HCO: 0,
    product: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [alert, setAlert] = useState({ message: '', type: '' });
  const [alertOpen, setAlertOpen] = useState(false);
  const [alarm, setAlarm] = useState(alarmDefault);
  const [allRegisters, setAllRegisters] = useState({
    countries: [],
    HCOs: [],
    products: [],
  });
  const [isSearching, setIsSearching] = useState(false);
  const [isEditSearch, setIsEditSearch] = useState(false);
  const { user } = useAuthContext();

  const handleChangeSelect = (value, name) => {
    setSearchFilter((prevState) => {
      const field = prevState;
      field[name] = value;
      return {
        ...field,
      };
    });
    setIsReset(true);
  };

  const handleModalOpen = () => {
    setAlarm(alarmDefault);
    setOpen(true);
  };
  const handleModalClose = () => {
    setAlarm(alarmDefault);
    setOpen(false);
  };

  const handleAlertClick = (message, type) => {
    setAlert({
      message,
      type,
    });
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleClickSearch = (e) => {
    if (e.target.id === 'search') {
      setIsSearching(true);
      setIsUpdate(true);
    } else if (e.target.id === 'clear') {
      setSearchFilter({
        product: 0,
        HCO: 0,
        country: 0,
      });
      setIsUpdate(false);
    }
  };

  // POST request to add Alarm
  const handleAddAlarm = (addAlarm) => {
    const addAlarmData = {
      ...addAlarm,
      createdBy: user.user_id,
      updatedBy: user.user_id,
      createdAt: format(new Date(), utils.dateFormat),
    };
    async function add() {
      await apiGatwayCall(user.token, addAlarmData, 'addalarm')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setOpen(false);
            setIsUpdate(true);
            handleAlertClick('Alarm added successfully.', 'success');
          } else if (statusCode === 503) {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    add();
  };

  // GET request to fetch Alarm data
  const handleEditAlarm = (aId) => {
    setIsEditSearch(true);
    async function getData() {
      await apiGatwayCall(user.token, { id: aId }, 'getalarm')
        .then((response) => {
          const { data, message, statusCode } = response.data;
          if (statusCode === 200) {
            setAlarm({
              alarmId: data.alarm_id,
              product: data.product_id ?? 0,
              country: data.country_id ?? 0,
              name: data.name,
              periodId: data.enum_alarm_period_id,
              amount: data.amount,
              HCO: data.organization_id ?? 0,
            });
            setIsEditSearch(false);
            setOpen(true);
          } else {
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    getData();
    setIsUpdate(true);
  };

  // PUT request to update Alarm data
  const handleUpdateAlarm = (uAlarm) => {
    const updAlarmData = {
      ...uAlarm,
      id: uAlarm.alarmId,
      updatedAt: format(new Date(), utils.dateFormat),
      updatedBy: user.user_id,
    };
    async function putData() {
      await apiGatwayCall(user.token, updAlarmData, 'updatealarm')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setOpen(false);
            setIsUpdate(true);
            handleAlertClick('Alarm updated successfully.', 'success');
            setAlarm(alarmDefault);
          } else if (statusCode === 503) {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    putData();
  };

  // PUT request to delete Alarm data
  const handleDeleteAlarm = (delAlarmId) => {
    const delAlarmData = {
      id: delAlarmId,
      updatedBy: user.user_id,
      updatedAt: format(new Date(), utils.dateFormat),
    };
    async function deleteData() {
      await apiGatwayCall(user.token, delAlarmData, 'deletealarm')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setIsUpdate(true);
            handleAlertClick('Alarm removed successfully.', 'success');
          } else {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    deleteData();
  };

  // GET request to fetch Alarm list
  useEffect(() => {
    async function getTable() {
      const filterData = {
        countryId: searchFilter.country,
        organizationId: searchFilter.HCO,
        productId: searchFilter.product,
      };

      await apiGatwayCall(user.token, filterData, 'getalarmList')
        .then((response) => {
          const { data, message, statusCode } = response.data;
          if (statusCode === 200) {
            const rows = data.map((a) => ({
              Id: a.alarm_id,
              data: [
                a.name,
                a.country_id != null ? a.country : 'All countries',
                a.organization_id != null ? a.hco : 'All organizations',
                a.product_id != null ? a.product : 'All products',
                a.amount,
              ],
            }));
            setTableRows(rows);
          } else if (statusCode === 404) {
            setTableRows([]);
          } else {
            handleAlertClick(message, 'error');
          }
          setIsSearching(false);
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    if (isUpdate) {
      getTable();
      setIsUpdate(false);
    }
  }, [isUpdate]);

  // GET request to fetch Country, Product, HCO data
  useEffect(() => {
    async function getData() {
      const tableData = await getAll(user.token, getTableData);
      const enumData = await getEnum(user.token, getEnumData);

      const ctr = enumData.country.map((e) => ({
        id: e.country_id,
        name: e.name,
        code: e.code,
      }));
      ctr.splice(0, 0, { id: 0, name: 'Applies to All Countries' });

      const alarmPeriod = enumData.enum_alarm_period.map((e) => ({
        id: e.enum_alarm_period_id,
        name: e.description,
      }));

      let org = [];
      let prd = [];

      if (tableData.organization && tableData.organization.length > 0) {
        org = tableData.organization.map((e) => ({
          id: e.organization_id,
          name: `${ctr[e.country_id].code} - ${e.name}`,
          countryId: e.country_id,
        }));
      }
      if (tableData.product && tableData.product.length > 0) {
        prd = tableData.product.map((e) => ({
          id: e.product_id,
          name: `${ctr[e.country_id].code} - ${e.name}`,
          countryId: e.country_id,
        }));
      }
      prd.splice(0, 0, { id: 0, name: 'Applies to All Products' });
      org.splice(0, 0, { id: 0, name: 'Applies to All HCOs' });

      setAllRegisters({
        HCOs: org,
        countries: ctr,
        products: prd,
      });

      prd = prd.filter((h) =>
        searchFilter.country !== 0
          ? h.countryId === searchFilter.country || h.id === 0
          : true
      );
      org = org.filter((h) =>
        searchFilter.country !== 0
          ? h.countryId === searchFilter.country || h.id === 0
          : true
      );
      if (org && alarmPeriod && ctr && prd) {
        setStaticData({
          HCOs: org,
          countries: ctr,
          alarmPeriod: alarmPeriod,
          products: prd,
        });

        setIsLoading(false);
        setIsReset(false);
      }
    }
    getData();
  }, [isReset]);

  return (
    <div>
      {isLoading ? (
        <BackdropLoader show={isLoading} />
      ) : (
        <div>
          <BackdropLoader show={isEditSearch} />
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleAlertClose}
          >
            <Alert onClose={handleAlertClose} severity={alert.type}>
              {alert.message}
            </Alert>
          </Snackbar>
          <Grid container item xs={12}>
            <h2 className='product-title'>Alarm List</h2>
          </Grid>

          <Grid container direction='row' spacing={2}>
            <Grid container item direction='row' spacing={1}>
              <Grid item xs={12} sm={6} md={3} lg={3}>
                <label className='form-label' htmlFor='country'>
                  Country
                </label>
                <SelectFilter
                  id='country'
                  selected={searchFilter.country}
                  name='country'
                  getSelected={handleChangeSelect}
                  minWidth='100%'
                  optionList={staticData.countries}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <label className='form-label' htmlFor='HCO'>
                  HCOs
                </label>
                <SelectFilter
                  id='HCO'
                  selected={searchFilter.HCO}
                  getSelected={handleChangeSelect}
                  labelName='HCO'
                  minWidth='100%'
                  name='HCO'
                  isEmpty={staticData.HCOs.length === 1}
                  optionList={staticData.HCOs}
                />
              </Grid>
            </Grid>
            <Grid container item direction='row' spacing={1}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <label className='form-label' htmlFor='product'>
                  Products
                </label>
                <SelectFilter
                  id='product'
                  selected={searchFilter.product}
                  getSelected={handleChangeSelect}
                  labelName='products'
                  minWidth='100%'
                  name='product'
                  isEmpty={staticData.products.length === 1}
                  optionList={staticData.products}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Button
                  id='search'
                  variant='contained'
                  className='search-btn'
                  onClick={handleClickSearch}
                  disabled={
                    !!(
                      staticData.products.length === 1 &&
                      staticData.HCOs.length === 1
                    )
                  }
                  startIcon={<SearchIcon />}
                >
                  Search
                </Button>
                <Button
                  id='clear'
                  variant='contained'
                  className='search-btn'
                  onClick={handleClickSearch}
                  startIcon={<ClearIcon />}
                >
                  Clear Search
                </Button>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                alignItems='center'
                justifyContent='flex-end'
              >
                <Button
                  className='form-btn'
                  size='medium'
                  color='success'
                  variant='contained'
                  onClick={handleModalOpen}
                  startIcon={<AddIcon size='small' />}
                >
                  Add New Alarm
                </Button>
                <Modal
                  open={open}
                  onClose={(_, reason) => {
                    if (reason !== 'backdropClick') {
                      handleModalClose();
                    }
                  }}
                  aria-labelledby='parent-modal-title'
                  aria-describedby='parent-modal-description'
                >
                  <div>
                    <Box className='product_modal_box responsive-modal'>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <h4 className='modaltitle'>
                          {alarm.alarmId === 0
                            ? 'Create New Alarm'
                            : 'Edit Alarm'}
                        </h4>
                        <Button onClick={handleModalClose}>
                          <ClearIcon />
                        </Button>
                      </div>
                      <Alarmform
                        countries={allRegisters.countries}
                        HCOs={allRegisters.HCOs}
                        products={allRegisters.products}
                        alarmPeriod={staticData.alarmPeriod}
                        updateAlarmData={handleUpdateAlarm}
                        getAlarmData={handleAddAlarm}
                        alarm={alarm}
                        handleClose={handleModalClose}
                      />
                    </Box>
                  </div>
                </Modal>
              </Grid>
            </Grid>
          </Grid>
          <RegistersTable
            searching={isSearching}
            register={alarmData}
            rows={tableRows}
            handleEdit={handleEditAlarm}
            handleDelete={handleDeleteAlarm}
          />
        </div>
      )}
    </div>
  );
}
