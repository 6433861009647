import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Grid, Snackbar } from '@mui/material';
import { format, subYears } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { printLetters } from './Reports';
import RequestFilter from '../Components/RequestFilter';
import ReportButtons from '../Components/ReportButtons';
import { Alert } from '../Components/UI/Common';
import BackdropLoader from '../Components/UI/BackdropLoader';
import RegistersTable from '../Components/UI/RegistersTable';
import { apiGatwayCall, getAll } from '../Components/Utils/utils';
import getEnum from '../Components/Utils/Enum';
import { useAuthContext } from '../Components/Auth/authContext';
import { donationRequestHeads, utils } from '../Data';
import '../Styles/Products.css';

// This component renders dashboard for Donation Requests
const defaultFilter = {
  countryId: 1,
  organizationId: 0,
  search: '',
  periodStart: format(subYears(new Date(), 2), utils.dateFormat),
  periodEnd: format(new Date(), utils.dateFormat),
  requestPeriodId: 1,
  requestStatusId: 0,
};

const selectData = ['enum_request_status', 'enum_request_period', 'country'];

function Dashboard() {
  const [tableRows, setTableRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdate, setIsUpdate] = useState(true);
  const [filter, setFilter] = useState(defaultFilter);
  const [periodRepresent, setPeriodRepresent] = useState([]);
  const [HCOs, setHCOs] = useState([]);
  const [requestStatus, setRequestStatus] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [alert, setAlert] = useState({ message: '', type: '' });
  const [alertOpen, setAlertOpen] = useState(false);
  const { user } = useAuthContext();
  const history = useHistory();

  const fwdStates = {
    searchState: {
      get: isSearching,
      set: setIsSearching,
    },
  };

  const handleAlertClick = (message, type) => {
    setAlert({
      message,
      type,
    });
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  // GET request to fetch all Donation Requests, HCOs, Status, Countries from database
  useEffect(() => {
    async function getData() {
      const enitityData = await getEnum(user.token, selectData);
      const tableData = await getAll(user.token, ['organization']);
  
      const enumStatus = enitityData.enum_request_status.map((e) => ({
        id: e.enum_request_status_id,
        name: e.description,
      }));
      enumStatus.splice(0, 0, { id: 0, name: 'All Status' });
  
      setRequestStatus(enumStatus);
  
      const enumPeriod = enitityData.enum_request_period.map((e) => ({
        id: e.enum_request_period_id,
        name: e.description,
      }));
      setPeriodRepresent(enumPeriod);
  
      let org = [];
      if (tableData.organization && tableData.organization.length > 0) {
        org = tableData.organization;
        org = org.map((e) => ({
          id: e.organization_id,
          name: e.name,
          countryId: e.country_id,
        }));
      }
      org.splice(0, 0, { id: 0, name: 'All HCOs' });
      setHCOs(org);
  
      const ctr = enitityData.country.map((c) => ({
        id: c.country_id,
        name: c.name,
      }));
      setAllCountries(ctr);
  
      if (HCOs && requestStatus && periodRepresent && allCountries && tableRows) {
        setIsLoading(false);
      }
    }
    getData();
  }, []);

  async function getOrganizationRequest(filterOptions) {
    await apiGatwayCall(
      user.token,
      { filterOptions },
      'getorganizationRequestList'
    )
      .then((response) => {
        const { data, message, statusCode } = response.data;
        if (statusCode === 200) {
          const rows = data.map((orgR) => ({
            Id: orgR.organization_request_id,
            data: [
              `${orgR.full_number} \n ${orgR.name}`,
              orgR.enum_description,
              orgR.date_request
                ? format(new Date(orgR.date_request), 'dd-MM-yyyy')
                : '',
              orgR.date_donation
                ? format(new Date(orgR.date_donation), 'dd-MM-yyyy')
                : '',
            ],
          }));
          setTableRows(rows);
        } else if (statusCode === 404) {
          setTableRows([]);
        } else {
          handleAlertClick(message, 'error');
        }
        setIsSearching(false);
      })
      .catch(() => handleAlertClick(utils.errorMessage, 'error'));
  }

  // GET request to fetch all Donation Requests from database
  useEffect(async () => {
    const filterOptions = filter;
    if (isUpdate) {
      getOrganizationRequest(filterOptions);
      setIsUpdate(false);
    }
  }, [isUpdate]);

  const handleSearched = (serachData) => {
    setFilter({ ...serachData, updatedBy: user.user_id });
    setIsUpdate(true);
  };

  const handleDonationHistory = async (orReqId) => {
    handleAlertClick('Opening Donation History...', 'info');
    const reportName = 'Donation History';
    const letterData = {
      requestId: orReqId, 
      updatedAt: format(new Date(), utils.reportDateFormat),
    }
    const errMessage = await printLetters(user.token, reportName, letterData);
    if (errMessage !== '') {
      handleAlertClick(errMessage, 'error');
    }
  };

  const handleDonationAppendix = async (orReqId) => {
    handleAlertClick('Opening Appendix...', 'info');
    const reportName = 'Appendix';
    const errMessage = await printLetters(user.token, reportName, orReqId);
    if (errMessage !== '') {
      handleAlertClick(errMessage, 'error');
    }
  };

  const handleDonationLetter = async (orReqId) => {
    handleAlertClick('Opening Donation Letter...', 'info');
    const reportName = 'Donation Letter';
    const errMessage = await printLetters(user.token, reportName, orReqId);
    if (errMessage !== '') {
      handleAlertClick(errMessage, 'error');
    }
  };

  // GET request to fetch single Donation Request data from database
  const handleEditDonation = (orReqId) => {
    async function getData() {
      await apiGatwayCall(user.token, { id: orReqId }, 'getorganizationRequest')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            history.push(`/createdonation/?reqId=${orReqId}`);
          } else {
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    getData();
    setIsUpdate(true);
  };

  // PUT request to delete a Donation Request
  const handleDeleteDonation = (delORId) => {
    setIsDeleting(true);
    const delORData = {
      id: delORId,
      updatedBy: user.user_id,
      updatedAt: format(new Date(), utils.dateFormat),
    };
    handleDeleteS3Files(delORData);
  };

  async function deleteData(delORData) {
    await apiGatwayCall(user.token, delORData, 'deleteorganizationRequest')
      .then((response) => {
        const { message, statusCode } = response.data;
        if (statusCode === 200) {
          setIsUpdate(true);
          handleAlertClick(
            'Donation request removed successfully.',
            'success'
          );
        } else {
          setIsUpdate(true);
          handleAlertClick(message, 'error');
        }
        setIsDeleting(false);
      })
      .catch((err) => {
        handleAlertClick(utils.errorMessage, 'error');
        setIsDeleting(false);
      });
  }

  async function handleDeleteS3Files(delORData) {
    let promises = [];
    await apiGatwayCall(user.token, delORData, 'getsignedUrlDeleteForAll')
      .then(async (response) => {
        const { data, message, statusCode } = response.data;
        if(statusCode === 200) {
          data.forEach(async f => {
            await axios({
              method: 'delete',
              url: f.data,
            });
          });
        } 
        await deleteData(delORData);
      });
  }

  return (
    <div>
      {isLoading ? (
        <BackdropLoader show={isLoading} />
      ) : (
        <div>
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleAlertClose}
          >
            <Alert
              onClose={handleAlertClose}
              severity={alert.type}
              sx={{ width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Snackbar>
          <Grid container item xs={12}>
            <h2 className='product-title'>Donation Requests</h2>
          </Grid>

          <RequestFilter
            fwdState={fwdStates.searchState}
            countries={allCountries}
            HCOs={HCOs}
            requestStatus={requestStatus}
            requestPeriod={periodRepresent}
            getData={handleSearched}
            defaultFilterVal={defaultFilter}
          />

          <Grid container direction='row' alignItems='center'>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <ReportButtons
                filter={filter}
                isDisable={tableRows.length === 0}
              />
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              alignSelf='end'
              justifyContent='flex-end'
            >
              {user.role === 'ADMIN' && (
                <Link className='button_link' to='/createdonation/'>
                  <Button
                    sx={{ backgroundColor: '#5cb85c !important' }}
                    className='dashboard-btn'
                    size='medium'
                    variant='contained'
                    startIcon={<AddIcon size='large' />}
                  >
                    Create New Donation
                  </Button>
                </Link>
              )}
            </Grid>
          </Grid>

          <RegistersTable
            deleting={isDeleting}
            searching={isSearching}
            register={donationRequestHeads}
            rows={tableRows}
            filter={filter}
            isDelete={user.role !== 'ADMIN'}
            handleEdit={handleEditDonation}
            handleDelete={handleDeleteDonation}
            handleDonationHistory={handleDonationHistory}
            handleDonationLetter={handleDonationLetter}
            handleDonationAppendix={handleDonationAppendix}
          />
        </div>
      )}
    </div>
  );
}

export default Dashboard;
