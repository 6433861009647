const utils = {
  dateFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS",
  reportDateFormat: 'dd/MM/yyyy HH:mm:ss a',
  errorMessage: 'Error! Please try again later.',
};

const returnDialog = {
  title: 'Alert!',
  message: 'Do you want to leave this page?',
  leftBtn: 'No',
  rightBtn: 'Yes',
};

const reportButtons = [
  {
    id: 1,
    btnName: 'Summary Report',
    bgColor: '#5bc0de !important',
    tooltipTitle: 'View Summary Report',
  },
  {
    id: 2,
    btnName: 'HUB Report',
    bgColor: '#f0ad4e !important',
    tooltipTitle: 'View HUB Report for Period',
  },
  {
    id: 3,
    btnName: 'HUB Excel',
    bgColor: '#5cb85c !important',
    tooltipTitle: 'Export HUB Report for Period',
  },
  {
    id: 4,
    btnName: 'Yearly Limit Report',
    bgColor: '#d9534f !important',
    tooltipTitle: 'View Yearly limit Report',
  },
  {
    id: 5,
    btnName: 'Q Report',
    bgColor: '#428bca !important',
    tooltipTitle: 'Export Q Report',
  },
];

const donationRequestHeads = {
  tablehead: ['Request ID \n HCO', 'Status', 'Request Date', 'Donation Date', 'Print', 'Edit/Delete'],
  tableName: 'Request',
};

const products = {
  tablehead: ['Name', 'Product Code', 'Price', 'Enum Curency Code', 'Description', 'Edit/Delete'],
  tableName: 'Product',

};

const donationProducts = {
  tablehead: ['Product', 'Requested Quantity', 'Approved Quantity', 'Donated Quantity', 'Edit/Delete'],
  tableName: 'Request Product',
};

const HCOsData = {
  tablehead: ['Name', 'Alias', 'Code', 'Address', 'City', 'Description', 'Edit/Delete'],
  tableName: 'HCO',
  helperText: 'Must be at-least 3 chars',

};

const distributorData = {
  tablehead: ['Name', 'Edit/Delete'],
  tableName: 'Distributor',

};
const alarmData = {
  tablehead: ['Name', 'Country', 'HCO', 'Product', 'Amount', 'Edit/Delete'],
  tableName: 'Alarm',

};

const currencyData = {
  tablehead: ['Currency', 'Value', 'Valid from', 'Edit'],
};

const userList = {
  tablehead: ['User Name', 'Lilly ID', 'Role', 'Edit/Delete'],
  tableName: 'User',
  helperTextEmail: 'Must be a valid email',
  helperText: 'Must be at-least 3 chars',
};

export {
  returnDialog, donationRequestHeads, donationProducts, userList, alarmData,
  currencyData, HCOsData, distributorData, reportButtons, products, utils,
};
