import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, TextField, Tooltip, Zoom } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SelectFilter from '../Components/UI/SelectFilter';
import { Asterisk, Labels, checkFormat } from '../Components/UI/Common';
import '../Styles/Products.css';

const useStyles = makeStyles(() => ({
  gridHeight: {
    height: '60px',
  },
}));

const alarmDefault = {
  country: 0,
  name: '',
  HCO: 0,
  periodId: 1,
  product: 0,
  amount: '',
  alarmId: 0,
};

const errorMessage = {
  name: 'Must be at-least 3 chars',
  amount: '',
};

function Alamform(props) {
  const classes = useStyles();
  const {
    products,
    HCOs,
    countries,
    alarmPeriod,
    alarm,
    getAlarmData,
    updateAlarmData,
    handleClose,
  } = props;
  const [alarmData, setAlarmData] = useState(alarmDefault);
  const [isReset, setIsReset] = useState(true);
  const [err, setErr] = useState(errorMessage);
  const [registers, setRegisters] = useState({
    HCOs: HCOs,
    products: products,
  });

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name } = e.target;
    const { value } = e.target;
    setAlarmData((prevState) => {
      const field = prevState;
      field[name] = value;
      return {
        ...field,
      };
    });

    if (name === 'amount') {
      if (value !== '' && !checkFormat(value, 1)) {
        setErr({
          amount: 'Must be positive integer/decimal',
        });
      } else {
        setErr({
          amount: '',
        });
      }
    }
  };

  const handleChangeSelect = (value, name) => {
    setAlarmData((prevState) => {
      const field = prevState;
      field[name] = value;
      return {
        ...field,
      };
    });

    if (name === 'country') {
      setIsReset(true);
    }
  };

  useEffect(() => {
    setAlarmData({ ...alarm });
  }, []);

  useEffect(() => {
    const prd = products.filter(
      (h) =>
        h.countryId === alarmData.country ||
        h.id === 0 ||
        alarmData.country === 0 ||
        alarmData.country === null
    );
    const org = HCOs.filter(
      (h) =>
        h.countryId === alarmData.country ||
        h.id === 0 ||
        alarmData.country === 0 ||
        alarmData.country === null
    );
    setRegisters({
      HCOs: org,
      products: prd,
    });
    setIsReset(false);
  }, [isReset]);

  const handleClickSave = () => {
    const addAlarmData = {
      countryId: alarmData.country,
      name: alarmData.name,
      productId: alarmData.product,
      organizationId: alarmData.HCO,
      periodId: alarmData.periodId,
      amount: alarmData.amount,
      alarmId: alarmData.alarmId,
    };
    if (alarm.alarmId === 0) {
      getAlarmData(addAlarmData);
    } else {
      updateAlarmData(addAlarmData);
    }
    handleClose();
  };

  function SubmitButton() {
    if (
      alarmData.name &&
      alarmData.name.length >= 3 &&
      alarmData.amount &&
      registers.HCOs.length !== 1 &&
      registers.products.length !== 1 &&
      err.amount === ''
    ) {
      return (
        <Button onClick={handleClickSave} variant='contained'>
          Save Changes
        </Button>
      );
    } else {
      return (
        <Tooltip
          TransitionComponent={Zoom}
          arrow
          placement='top'
          title='Fill mandatory fields to enable'
          enterTouchDelay={0}
        >
          <span>
            <Button variant='contained' disabled>
              Save Changes
            </Button>
          </span>
        </Tooltip>
      );
    }
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Labels align='right' variant='subtitle1'>
            Name
            <Asterisk>*</Asterisk>
          </Labels>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={6}
          classes={{ root: classes.gridHeight }}
        >
          <TextField
            sx={{ width: '90%' }}
            id='name'
            size='small'
            name='name'
            value={alarmData.name}
            helperText={errorMessage.name}
            onChange={handleOnChange}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Labels align='right' variant='subtitle1'>
            Period
          </Labels>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={6}
          classes={{ root: classes.gridHeight }}
        >
          <SelectFilter
            minWidth='90%'
            name='periodId'
            selected={alarmData.periodId}
            optionList={alarmPeriod}
            getSelected={handleChangeSelect}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Labels align='right' variant='subtitle1'>
            Country
          </Labels>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={6}
          classes={{ root: classes.gridHeight }}
        >
          <SelectFilter
            minWidth='90%'
            name='country'
            selected={alarmData.country}
            optionList={countries}
            getSelected={handleChangeSelect}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Labels align='right' variant='subtitle1'>
            Organisation
          </Labels>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={6}
          classes={{ root: classes.gridHeight }}
        >
          <SelectFilter
            minWidth='90%'
            isEmpty={registers.HCOs.length === 1}
            optionList={registers.HCOs}
            name='HCO'
            selected={alarmData.HCO}
            getSelected={handleChangeSelect}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Labels align='right' variant='subtitle1'>
            Product
          </Labels>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={6}
          classes={{ root: classes.gridHeight }}
        >
          <SelectFilter
            minWidth='90%'
            isEmpty={registers.products.length === 1}
            optionList={registers.products}
            name='product'
            selected={alarmData.product}
            getSelected={handleChangeSelect}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Labels align='right' variant='subtitle1'>
            Amount
            <Asterisk>*</Asterisk>
          </Labels>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={6}
          classes={{ root: classes.gridHeight }}
        >
          <TextField
            sx={{ width: '90%' }}
            id='amount'
            size='small'
            name='amount'
            value={alarmData.amount}
            onChange={handleOnChange}
            type='number'
            inputProps={{ min: 1 }}
            error={err.amount !== ''}
            helperText={err.amount}
          />
        </Grid>
      </Grid>

      <div className='buttondiv_productform'>
        <Button variant='outlined' onClick={handleClose}>
          Cancel
        </Button>
        <SubmitButton />
      </div>
    </div>
  );
}

Alamform.propTypes = {
  products: PropTypes.instanceOf(Object).isRequired,
  HCOs: PropTypes.instanceOf(Object).isRequired,
  countries: PropTypes.instanceOf(Object).isRequired,
  alarmPeriod: PropTypes.instanceOf(Array).isRequired,
  alarm: PropTypes.instanceOf(Object).isRequired,
  getAlarmData: PropTypes.func.isRequired,
  updateAlarmData: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Alamform;
