import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  Modal,
  Snackbar,
  TextField,
  Tooltip,
  Zoom,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useAuthContext } from '../Components/Auth/authContext';
import BackdropLoader from '../Components/UI/BackdropLoader';
import RegistersTable from '../Components/UI/RegistersTable';
import SelectFilter from '../Components/UI/SelectFilter';
import { Alert } from '../Components/UI/Common';
import { apiGatwayCall, csvToArr } from '../Components/Utils/utils';
import getEnum from '../Components/Utils/Enum';
import HCOform from './HCOform';
import { utils, HCOsData } from '../Data';
import '../Styles/Products.css';

const Input = styled('input')({
  display: 'none',
});

const defaultHCO = {
  organizationId: '',
  name: '',
  code: '',
  countryId: 1,
  localName: '',
  alias: '',
  address: '',
  description: '',
  postOffice: '',
  city: '',
  agreementDate: new Date(),
  personName: '',
  personTitle: '',
  HCOInstitutionalNo: '',
  HCONationalNo: '',
};

export default function HCOs() {
  const match = useMediaQuery('(max-width: 1100px)');
  const [isLoading, setIsLoading] = useState(true);
  const [allCountries, setAllCountries] = useState([]);
  const [country, setCountry] = useState(defaultHCO.countryId);
  const [search, setSearch] = useState('');
  const [tableRows, setTableRows] = useState([]);
  const [isUpdate, setIsUpdate] = useState(true);
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ message: '', type: '' });
  const [alertOpen, setAlertOpen] = useState(false);
  const [HCO, setHCO] = useState(defaultHCO);
  const [isSearching, setIsSearching] = useState(false);
  const [isEditSearch, setIsEditSearch] = useState(false);
  const { user } = useAuthContext();

  const handleAlertClick = (message, type) => {
    setAlert({
      message,
      type,
    });
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleChangeCountry = (value) => {
    setCountry(value);
  };

  const handleModalOpen = () => {
    setOpen(true);
    setHCO(defaultHCO);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleImportFile = async (e) => {
    let hcoArray = [];
    const countries = allCountries;
    let errorMessage = '';
    const reader = new FileReader();
    reader.onload = async function (event) {
      const data = event.target.result;
      hcoArray = csvToArr(data);
      hcoArray.forEach((row, i) => {
        if (i === 0) return;
        if (row.length === 3) {
          const icountry = countries.find((c) => {
            if (c.code == row[0]) {
              return c.id;
            }
          });
          if (icountry) {
            row[3] = icountry.id;
          } else {
            errorMessage += `Line ${i + 1} has unknown country code,\n`;
          }
        } else {
          errorMessage += `Line ${i + 1} has invalid number of columns,\r\n`;
        }
      });
      if (errorMessage === '') {
        hcoArray.shift();
        const importHcoData = {
          fileData: hcoArray,
          agreementDate: format(new Date(), utils.dateFormat),
          updatedAt: format(new Date(), utils.dateFormat),
          createdBy: user.user_id,
          updatedBy: user.user_id,
        };

        await apiGatwayCall(user.token, importHcoData, 'importorganizations')
          .then((response) => {
            const { message, statusCode } = response.data;
            if (statusCode === 200) {
              setIsUpdate(true);
              handleAlertClick(message, 'success');
            } else {
              setIsUpdate(true);
              handleAlertClick(message, 'error');
            }
          })
          .catch(() => handleAlertClick(utils.errorMessage, 'error'));
      } else {
        handleAlertClick(`Import Failed, ${errorMessage}`, 'error');
      }
    };
    if (e.target.files) {
      const fileName = e.target.files[0].name;
      const isValid = checkValidFileType(fileName);
      if (isValid) {
        reader.readAsArrayBuffer(e.target.files[0]);
        e.target.value = '';
      } else {
        handleAlertClick(`Invalid file type! File type must either be CSV or Excel`, 'error');
      }
    }
  };

  const checkValidFileType = (name) => {
    const lastDotIndex = name.lastIndexOf('.');
    const ext = name.substring(lastDotIndex + 1);
    const allowedType = ['csv', 'xls', 'xlsx', 'number'];
    const isValid = allowedType.includes(ext);
    return isValid;
  };

  // POST Request to search HCOs
  const handleClickSearch = (e) => {
    if (e.target.id === 'search') {
      setIsSearching(true);
      setIsUpdate(true);
    } else if (e.target.id === 'clear') {
      setCountry(defaultHCO.countryId);
      setSearch('');
      setIsUpdate(false);
    }
  };

  // POST request to add HCO
  const handleAddHCO = (aHco) => {
    const addHcoData = {
      ...aHco,
      createdBy: user.user_id,
      updatedBy: user.user_id,
      createdAt: format(new Date(), utils.dateFormat),
    };

    async function add() {
      await apiGatwayCall(user.token, addHcoData, 'addorganization')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setOpen(false);
            setIsUpdate(true);
            handleAlertClick('HCO added successfully.', 'success');
          } else if (statusCode === 503) {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    add();
  };

  // PUT request to update an HCO
  const handleUpdateHCO = (updHco) => {
    const updHcoData = {
      ...updHco,
      id: HCO.organizationId,
      updatedAt: format(new Date(), utils.dateFormat),
      updatedBy: user.user_id,
    };
    async function putData() {
      await apiGatwayCall(user.token, updHcoData, 'updateorganization')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setIsUpdate(true);
            handleAlertClick('HCO updated successfully.', 'success');
          } else if (statusCode === 503) {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    putData();
    setOpen(false);
  };

  // GET request to get an HCO data
  const handleEditHCO = (hId) => {
    setIsEditSearch(true);
    async function getData() {
      await apiGatwayCall(user.token, { id: hId }, 'getorganization')
        .then((response) => {
          const { data, message, statusCode } = response.data;
          if (statusCode === 200) {
            setHCO({
              organizationId: data[0].organization_id,
              countryId: data[0].country_id,
              code: data[0].code,
              name: data[0].name,
              localName: data[0].local_name,
              alias: data[0].alias,
              address: data[0].address,
              description: data[0].description,
              postOffice: data[0].postoffice,
              city: data[0].city,
              agreementDate: data[0].agreement_date,
              personName: data[0].person_name,
              personTitle: data[0].person_title,
              HCOInstitutionalNo: data[0].HCO_institutional_no,
              HCONationalNo: data[0].HCO_national_no,
            });
            setIsEditSearch(false);
            setOpen(true);
          } else {
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    getData();
    setIsUpdate(true);
  };

  // POST request to delete an HCO
  const handleDeleteHCO = (delHcoId) => {
    const delHCOData = {
      id: delHcoId,
      updatedBy: user.user_id,
      updatedAt: format(new Date(), utils.dateFormat),
    };
    async function deleteData() {
      await apiGatwayCall(user.token, delHCOData, 'deleteorganization')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setIsUpdate(true);
            handleAlertClick('HCO removed successfully.', 'success');
          } else {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    deleteData();
  };

  // GET request to fetch all HCOs from database
  useEffect(() => {
    async function getHCOs() {
      await apiGatwayCall(
        user.token,
        { countryid: country, search },
        'getorganizationList'
      )
        .then((response) => {
          const { data, message, statusCode } = response.data;
          if (statusCode === 200) {
            const rows = data.map((h) => ({
              Id: h.organization_id,
              data: [h.name, h.alias, h.code, h.address, h.city, h.description],
            }));
            setTableRows(rows);
          } else if (statusCode === 404) {
            setTableRows([]);
          } else {
            handleAlertClick(message, 'error');
          }
          setIsSearching(false);
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    if (isUpdate) {
      getHCOs();
      setIsUpdate(false);
    }
  }, [isUpdate, country]);

  // GET all countries from country table
  useEffect(() => {
    async function getCountries() {
      const countryData = await getEnum(user.token, ['country']);
      const ctr = countryData.country.map((c) => ({
        id: c.country_id,
        name: c.name,
        code: c.code,
      }));
      setAllCountries(ctr);
      setIsLoading(false);
    }
    getCountries();
  }, []);

  function ImportButton() {
    return match ? (
      <Tooltip
        TransitionComponent={Zoom}
        arrow
        placement='top'
        title='Import HCOs'
        enterTouchDelay={0}
      >
        <Button
          className='addbutton'
          size='small'
          variant='contained'
          component='span'
          color='info'
        >
          <UploadFileIcon fontSize='small' />
        </Button>
      </Tooltip>
    ) : (
      <Button
        className='form-btn'
        size='medium'
        variant='contained'
        component='span'
        startIcon={<UploadFileIcon size='small' />}
      >
        Import HCOs
      </Button>
    );
  }

  return (
    <div>
      {isLoading ? (
        <BackdropLoader show={isLoading} />
      ) : (
        <div>
          <BackdropLoader show={isEditSearch} />
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleAlertClose}
          >
            <Alert onClose={handleAlertClose} severity={alert.type}>
              {alert.message}
            </Alert>
          </Snackbar>
          <Grid container item xs={12}>
            <h2 className='product-title'>HCO List</h2>
          </Grid>

          <Grid container direction='row' spacing={1}>
            <Grid item xs={12} sm={3} md={2} lg={2}>
              <label className='form-label' htmlFor='country'>
                Country
              </label>
              <SelectFilter
                selected={country}
                getSelected={handleChangeCountry}
                minWidth='100%'
                optionList={allCountries}
                name='country'
                id='country'
              />
            </Grid>

            <Grid item xs={12} sm={9} md={4} lg={3}>
              <label className='form-label' htmlFor='searchTerm'>
                Search Term
              </label>
              <TextField
                sx={{ width: '100%' }}
                id='searchTerm'
                value={search}
                type='text'
                placeholder='Search by HCO name or code'
                onChange={handleChangeSearch}
                size='small'
              />
            </Grid>

            <Grid item xs={12} sm={6} md={5} lg={3}>
              <Button
                id='search'
                variant='contained'
                className='search-btn'
                onClick={handleClickSearch}
                startIcon={<SearchIcon />}
              >
                Search
              </Button>
              <Button
                id='clear'
                variant='contained'
                className='search-btn'
                startIcon={<ClearIcon />}
                onClick={handleClickSearch}
              >
                Clear Search
              </Button>
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={6}
              md={12}
              lg={4}
              alignItems='center'
              justifyContent='flex-end'
            >
              <Button
                onClick={handleModalOpen}
                className='form-btn'
                size='medium'
                color='success'
                variant='contained'
                startIcon={<AddIcon size='small' />}
              >
                Add New HCO
              </Button>

              <label htmlFor='importFile'>
                <Input
                  id='importFile'
                  onChange={handleImportFile}
                  type='file'
                  accept='.xlsx, .xls, .csv, .numbers'
                />
                <ImportButton />
              </label>

              <Modal
                open={open}
                onClose={(_, reason) => {
                  if (reason !== 'backdropClick') {
                    handleModalClose();
                  }
                }}
                aria-labelledby='parent-modal-title'
                aria-describedby='parent-modal-description'
              >
                <div>
                  <Box className='product_modal_box' sx={{ width: '90%' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h4 className='modaltitle'>
                        {HCO.organizationId == ''
                          ? 'Create New HCO'
                          : 'Edit HCO'}
                      </h4>
                      <Button onClick={handleModalClose}>
                        <ClearIcon />
                      </Button>
                    </div>
                    <HCOform
                      content={HCOsData}
                      editHCO={HCO}
                      countries={allCountries}
                      updateHCOData={handleUpdateHCO}
                      getHCOData={handleAddHCO}
                      handleClose={handleModalClose}
                    />
                  </Box>
                </div>
              </Modal>
            </Grid>
          </Grid>

          <RegistersTable
            searching={isSearching}
            register={HCOsData}
            rows={tableRows}
            handleEdit={handleEditHCO}
            handleDelete={handleDeleteHCO}
          />
        </div>
      )}
    </div>
  );
}
