import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Button, TextField, Grid, Tooltip, Zoom } from '@mui/material';
import '../Styles/Products.css';
import { format } from 'date-fns';
import { apiGatwayCall } from '../Components/Utils/utils';
import SelectFilter from '../Components/UI/SelectFilter';
import { useAuthContext } from '../Components/Auth/authContext';
import { Asterisk, Labels } from '../Components/UI/Common';
import { utils } from '../Data';

const useStyles = makeStyles(() => ({
  textField: {
    width: '90%',
  },
}));

const defaultUser = {
  user_id: '',
  lilly_id: '',
  user_name: '',
  user_email: '',
  user_role: '',
  createdby: 1,
  updatedby: 1,
};

function UserForm(props) {
  const classes = useStyles();
  const { roles, editUser, content, modalAction, handleClose } = props;
  const [userRoles, setUserRoles] = useState([]);
  const [newUser, setUser] = useState(defaultUser);
  const { user } = useAuthContext();

  const handleChangeSelect = (value, name) => {
    setUser((prevState) => {
      const field = prevState;
      field[name] = value;
      return {
        ...field,
      };
    });
  };

  const handleOnChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setUser((prevState) => {
      const field = prevState;
      field[name] = value;
      return {
        ...field,
      };
    });
  };

  useEffect(() => {
    let role = modalAction.toUpperCase() === 'ADD' ? 1 : editUser.user_role;
    setUser({ ...editUser, user_role: role });
    const filteredRoles = roles.filter((r) => r.id !== 0);
    setUserRoles(filteredRoles);
  }, []);

  const handleSubmit = async () => {
    const addUserData = {
      id: newUser.user_id,
      lillyId: newUser.lilly_id,
      userName: newUser.user_name,
      userEmail: newUser.user_email,
      userRole: newUser.user_role,
      createdby: user.user_id,
      updatedby: user.user_id,
      updatedat: format(new Date(), utils.dateFormat),
      createdat: format(new Date(), utils.dateFormat),
    };
    if (modalAction.toUpperCase() === 'ADD') {
      await apiGatwayCall(user.token, addUserData, 'adduser')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            handleClose('User added successfully.', 'success');
          } else if (statusCode === 503) {
            handleClose(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    } else if (modalAction.toUpperCase() === 'EDIT') {
      await apiGatwayCall(user.token, addUserData, 'updateuser')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            handleClose('User updated successfully.', 'success');
          } else if (statusCode === 503) {
            handleClose(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
  };

  const validateEmail = (email) =>
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  function SubmitButton() {
    if (
      newUser.lilly_id &&
      newUser.user_name &&
      newUser.user_email &&
      newUser.user_role &&
      newUser.lilly_id.length >= 3 &&
      newUser.user_name.length >= 3 &&
      validateEmail(newUser.user_email)
    ) {
      return (
        <Button variant='contained' onClick={handleSubmit}>
          Save Changes
        </Button>
      );
    }
    return (
      <Tooltip
        TransitionComponent={Zoom}
        arrow
        placement='top'
        title='Fill mandatory fields to enable'
        enterTouchDelay={0}
      >
        <span>
          <Button variant='contained' disabled>
            Save Changes
          </Button>
        </span>
      </Tooltip>
    );
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Labels align='right' variant='subtitle1'>
            Lilly ID
            <Asterisk>*</Asterisk>
          </Labels>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={6}>
          <TextField
            size='small'
            varient='outlined'
            name='lilly_id'
            disabled={newUser.user_id !== ''}
            onChange={handleOnChange}
            value={newUser.lilly_id}
            classes={{ root: classes.textField }}
            helperText={content.helperText}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Labels align='right' variant='subtitle1'>
            User Name
            <Asterisk>*</Asterisk>
          </Labels>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={6}>
          <TextField
            size='small'
            varient='outlined'
            onChange={handleOnChange}
            name='user_name'
            value={newUser.user_name}
            helperText={content.helperText}
            classes={{ root: classes.textField }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Labels align='right' variant='subtitle1'>
            User Email
            <Asterisk>*</Asterisk>
          </Labels>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={6}>
          <TextField
            size='small'
            varient='outlined'
            name='user_email'
            value={newUser.user_email}
            helperText={content.helperTextEmail}
            onChange={handleOnChange}
            classes={{ root: classes.textField }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Labels align='right' variant='subtitle1'>
            User Role
            <Asterisk>*</Asterisk>
          </Labels>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={6}>
          <SelectFilter
            selected={newUser.user_role}
            name='user_role'
            getSelected={handleChangeSelect}
            minWidth='90%'
            optionList={userRoles}
          />
        </Grid>
      </Grid>
      <div className='buttondiv_productform'>
        <Button variant='outlined' onClick={handleClose}>
          Cancel
        </Button>
        <SubmitButton />
      </div>
    </div>
  );
}

UserForm.propTypes = {
  roles: PropTypes.instanceOf(Array).isRequired,
  editUser: PropTypes.instanceOf(Object).isRequired,
  content: PropTypes.any.isRequired,
  modalAction: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UserForm;
