import { Paper, Typography } from '@mui/material';
import AuthNav from './authNav';

export default function AccessDenied() {
  return (
    <div>
      <AuthNav />
      <Paper
        elevation={3}
        sx={{
          margin: '5%',
          padding: '5%',
        }}
      >
        <Typography variant='h6' gutterBottom component='div'>
          You do not have permission to access the SEE Donation Tool. Please
          request system administrator to change your role or grant access to
          system.
        </Typography>
      </Paper>
    </div>
  );
}