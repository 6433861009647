import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Grid,
  Modal,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import { format, subYears, add } from 'date-fns';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import getEnum from '../Components/Utils/Enum';
import { apiGatwayCall } from '../Components/Utils/utils';
import SelectFilter from '../Components/UI/SelectFilter';
import BackdropLoader from '../Components/UI/BackdropLoader';
import RegistersTable from '../Components/UI/RegistersTable';
import DateUI from '../Components/UI/DateUI';
import { Asterisk, Alert, Labels, checkFormat } from '../Components/UI/Common';
import { useAuthContext } from '../Components/Auth/authContext';
import { currencyData, utils } from '../Data';

const useStyles = makeStyles({
  alarmTitle: {
    fontFamily: 'Abel, Arial, sans-serif',
    fontSize: '1.575rem',
    fontWeight: 'lighter',
    paddingLeft: '7px',
    textAlign: 'left',
  },
  gridHeight: {
    height: '55px', 
  },
});

const defaultCurrencySearch = {
  countryId: 1,
  country: '',
  date: new Date(),
};

export default function CurrencyList() {
  const classes = useStyles();
  const [tableRows, setTableRows] = useState([]);
  const [usdTableRows, setUsdTableRows] = useState([]);
  const [isUpdate, setIsUpdate] = useState(true);
  const [currencyinfo, setCurrencyinfo] = useState({});
  const [searchFields, setSearchFields] = useState(defaultCurrencySearch);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allCountries, setAllCountries] = useState([]);
  const [allCurrencies, setAllCurrencies] = useState([]);
  const [alert, setAlert] = useState({ message: '', type: '' });
  const [alertOpen, setAlertOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [err, setErr] = useState({
    actualValue: '',
  });
  const { user } = useAuthContext();

  const handleAlertClick = (message, type) => {
    setAlert({
      message,
      type,
    });
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleChangeValue = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setCurrencyinfo((prevState) => {
      const field = prevState;
      field[name] = value;
      return {
        ...field,
      };
    });

    if(name === 'actualvalue') {
      if (value !== '' && !checkFormat(value, 1)) {
        setErr({
          actualValue: 'Must be positive integer/decimal',
        });
      } else {
        setErr({
          actualValue: '',
        });
      }
    }
  };

  const handleChangeSelect = (value, name) => {
    setSearchFields((prevState) => {
      const field = prevState;
      field[name] = value;
      return {
        ...field,
      };
    });
  };

  const handleSearch = (e) => {
    if (e.target.id === 'search') {
      setSearchFields((prev) => {
        const ctr = allCountries.filter((c) => c.id === searchFields.countryId);
        return {
          ...prev,
          country: ctr[0].name,
        };
      });
      setIsSearching(true);
      setIsUpdate(true);
    } else if (e.target.id === 'clear') {
      setSearchFields({
        countryId: 1,
        date: new Date(),
        country: allCountries[0].name,
      });
      setIsUpdate(false);
    }
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    async function getCurrency() {
      const country = searchFields.countryId;
      const searchDate = format(new Date(searchFields.date), 'yyyy-MM-dd');
      await apiGatwayCall(
        user.token,
        { countryid: country, date: searchDate },
        'getcurrencyList'
      )
        .then((response) => {
          const { data, message, statusCode } = response.data;
          if (statusCode === 200) {
            let rows = data.filter((c) => c.enumcurrencyid !== 178);
            rows = rows.map((c) => ({
              Id: c.enumcurrencyid,
              data: [c.enumcurrencycode, c.value, c.date || ''],
            }));
            let usdRows = data.filter((c) => c.enumcurrencyid === 178);
            usdRows = usdRows.map((c) => ({
              Id: c.enumcurrencyid,
              data: [c.enumcurrencycode, c.value, c.date || ''],
            }));
            setAllCurrencies(data);
            setTableRows(rows);
            setUsdTableRows(usdRows);
          } else if (statusCode === 404) {
            setTableRows([]);
          } else {
            handleAlertClick(message, 'error');
          }
          setIsSearching(false);
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    if (isUpdate) {
      getCurrency();
      setIsUpdate(false);
    }
  }, [isUpdate]);

  // POST request to update Currency data
  const handleAddCurrency = () => {
    const addCurrencyData = {
      countryId: searchFields.countryId,
      value: currencyinfo.actualvalue,
      enumcurrencyId: currencyinfo.enumcurrencyid,
      date: searchFields.date,
      createdby: user.user_id,
      updatedby: user.user_id,
      createdat: format(new Date(), utils.dateFormat),
    };

    async function add() {
      await apiGatwayCall(user.token, addCurrencyData, 'addcurrency')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setOpen(false);
            setIsUpdate(true);
            handleAlertClick('Currency updated successfully.', 'success');
          } else if (statusCode === 503) {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    add();
  };

  // PUT request to update Currency
  const handleUpdateCurrency = () => {
    const updCurrencyData = {
      id: currencyinfo.currencylistid,
      value: currencyinfo.actualvalue,
      date: searchFields.date,
      updatedat: format(new Date(), utils.dateFormat),
      updatedby: user.user_id,
    };

    async function putData() {
      await apiGatwayCall(user.token, updCurrencyData, 'updatecurrency')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setIsUpdate(true);
            handleAlertClick('Currency updated successfully.', 'success');
            setOpen(false);
          } else if (statusCode === 503) {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    putData();
  };

  // Compare search and enum currency date
  const handleClickSave = () => {
    if (
      currencyinfo.date !== null &&
      format(new Date(searchFields.date), 'yyyy-MM-dd') === currencyinfo.date
    ) {
      handleUpdateCurrency();
    } else {
      handleAddCurrency();
    }
  };

  const handleEditCurrency = (cId) => {
    const filteredCurrency = allCurrencies.filter(
      (d) => d.enumcurrencyid === cId
    );
    setCurrencyinfo(filteredCurrency[0]);
    setOpen(true);
  };

  // GET request to fetch countries from country table
  useEffect(() => {
    async function getCountries() {
      const countryData = await getEnum(user.token, ['country']);

      const ctr = countryData.country.map((c) => ({
        id: c.country_id,
        name: c.name,
        code: c.code,
      }));
      setAllCountries(ctr);
      setIsLoading(false);
    }
    getCountries();
  }, []);

  function SubmitButton() {
    if (currencyinfo.actualvalue === '') {
      return (
        <Tooltip
          TransitionComponent={Zoom}
          arrow
          placement='top'
          title='Fill mandatory fields to enable'
          enterTouchDelay={0}
        >
          <span>
            <Button variant='contained' disabled>
              Save Changes
            </Button>
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Button onClick={handleClickSave} variant='contained'>
          Save Changes
        </Button>
      );
    }
  }

  return (
    <div>
      {isLoading ? (
        <BackdropLoader show={isLoading} />
      ) : (
        <div>
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleAlertClose}
          >
            <Alert
              onClose={handleAlertClose}
              severity={alert.type}
              sx={{ width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Snackbar>
          <Grid container item xs={12}>
            <h2 className='product-title'>Currency List</h2>
          </Grid>
          <Grid container direction='row' spacing={1}>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <label className='form-label'>Country</label>
              <SelectFilter
                getSelected={handleChangeSelect}
                selected={searchFields.countryId}
                minWidth='100%'
                name='countryId'
                optionList={allCountries}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <label className='form-label'>Currency Date</label>
              <DateUI
                clearAllowed={false}
                minDate={subYears(new Date(), 20)}
                maxDate={add(new Date(), { years: 20 })}
                getDate={handleChangeSelect}
                name='date'
                defaultValue={searchFields.date}
                width='100%'
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Button
                id='search'
                variant='contained'
                className='search-btn'
                startIcon={<SearchIcon />}
                onClick={handleSearch}
              >
                Search
              </Button>
              <Button
                id='clear'
                variant='contained'
                className='search-btn'
                startIcon={<ClearIcon />}
                onClick={handleSearch}
              >
                Clear Search
              </Button>
            </Grid>

            <Grid item xs={12}>
              <RegistersTable
                register={currencyData}
                rows={usdTableRows}
                isDelete={true}
                handleEdit={handleEditCurrency}
              />
            </Grid>

            <Grid item xs={3}>
              <Typography
                className={classes.alarmTitle}
                variant='h6'
                component='div'
              >
                {searchFields.country !== ''
                  ? searchFields.country
                  : allCountries[0].name}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <RegistersTable
                searching={isSearching}
                register={currencyData}
                rows={tableRows}
                isDelete={true}
                handleEdit={handleEditCurrency}
              />
            </Grid>
          </Grid>

          <Modal
            open={open}
            onClose={(_, reason) => {
              if (reason !== 'backdropClick') {
                handleModalClose();
              }
            }}
            aria-labelledby='parent-modal-title'
            aria-describedby='parent-modal-description'
          >
            <div>
              <Box className='product_modal_box' sx={{ width: '500px' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <h4 className='modaltitle'>Edit Currency</h4>
                  <Button onClick={handleModalClose}>
                    <ClearIcon />
                  </Button>
                </div>
                <Box
                  component='form'
                  noValidate
                  autoComplete='off'
                  sx={{ p: 3 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4} lg={4} alignSelf='center' classes={{ root: classes.gridHeight }}>
                      <Labels align='right' variant='subtitle1'>
                        Country
                      </Labels>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={8} alignSelf='center' classes={{ root: classes.gridHeight }}>
                      <Typography
                        display='inline'
                        variant='subtitle1'
                        gutterBottom
                      >
                        {searchFields.country !== ''
                          ? searchFields.country
                          : allCountries[0].name}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4} alignSelf='center' classes={{ root: classes.gridHeight }}>
                      <Labels align='right' variant='subtitle1'>
                        Value<Asterisk>*</Asterisk>
                      </Labels>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={8} classes={{ root: classes.gridHeight }}>
                      <TextField
                        sx={{ width: '100%' }}
                        id='actualvalue'
                        size='small'
                        name='actualvalue'
                        value={currencyinfo.actualvalue}
                        onChange={handleChangeValue}
                        type='number'
                        inputProps={{ min: 1}}
                        error={err.actualValue !== ''}
                        helperText={err.actualValue}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4} alignSelf='center' classes={{ root: classes.gridHeight }}>
                      <Labels align='right' variant='subtitle1'>
                        Currency Name
                      </Labels>
                    </Grid>

                    <Grid item xs={6} sm={3} md={3} lg={3} alignSelf='center' classes={{ root: classes.gridHeight }}>
                      <Typography
                        display='inline'
                        variant='subtitle1'
                        gutterBottom
                      >
                        {currencyinfo.enumcurrencycode}
                      </Typography>
                      <Asterisk>*</Asterisk>
                    </Grid>

                    <Grid item xs={6} sm={5} md={5} lg={5} alignSelf='center' classes={{ root: classes.gridHeight }}>
                      <Typography
                        display='inline'
                        variant='subtitle1'
                        gutterBottom
                      >
                        {format(new Date(searchFields.date), 'dd-MM-yyyy')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <div className='buttondiv_productform'>
                  <Button variant='outlined' onClick={handleModalClose}>
                    Cancel
                  </Button>
                  <SubmitButton />
                </div>
              </Box>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}
