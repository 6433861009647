import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  Modal,
  Snackbar,
  TextField,
  Tooltip,
  Zoom,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { format } from 'date-fns';
import SelectFilter from '../Components/UI/SelectFilter';
import BackdropLoader from '../Components/UI/BackdropLoader';
import RegistersTable from '../Components/UI/RegistersTable';
import { Asterisk, Alert, Labels } from '../Components/UI/Common';
import { apiGatwayCall } from '../Components/Utils/utils';
import { useAuthContext } from '../Components/Auth/authContext';
import getEnum from '../Components/Utils/Enum';
import { distributorData, utils } from '../Data';
import '../Styles/Products.css';

const defaultDistributor = {
  country: 1,
  name: '',
  distributorId: '',
};

export default function Distributors() {
  const [tableRows, setTableRows] = useState([]);
  const [country, setCountry] = useState(defaultDistributor.country);
  const [open, setOpen] = useState(false);
  const [distributor, setDistributor] = useState(defaultDistributor);
  const [isUpdate, setIsUpdate] = useState(true);
  const [alert, setAlert] = useState({ message: '', type: '' });
  const [alertOpen, setAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allCountries, setAllCountries] = useState([]);
  const [isSearching, setIsSearching] = useState(true);
  const [isEditSearch, setIsEditSearch] = useState(false);
  const { user } = useAuthContext();

  const handleAlertClick = (message, type) => {
    setAlert({
      message,
      type,
    });
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleSearchCountry = (value) => {
    setCountry(value);
    setIsSearching(true);
    setIsUpdate(true);
  };

  const handleModalOpen = () => {
    setDistributor({ ...defaultDistributor });
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleFormCountry = (value, name) => {
    setDistributor((prevState) => {
      const field = prevState;
      field[name] = value;
      return {
        ...field,
      };
    });
  };

  const handleOnChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setDistributor((prevState) => {
      const field = prevState;
      field[name] = value;
      return {
        ...field,
      };
    });
  };

  // POST request to add Distributor
  const handleAddDistributor = () => {
    const addDistData = {
      countryid: distributor.country,
      name: distributor.name,
      createdby: user.user_id,
      updatedby: user.user_id,
      createdAt: format(new Date(), utils.dateFormat),
    };

    async function add() {
      await apiGatwayCall(user.token, addDistData, 'adddistributor')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setOpen(false);
            setIsUpdate(true);
            handleAlertClick('Distributor added successfully.', 'success');
          } else if (statusCode === 503) {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    add();
  };

  // PUT request to update Distributor
  const handleUpdateDistributor = () => {
    const updDistData = {
      id: distributor.distributorId,
      countryid: distributor.country,
      name: distributor.name,
      updatedat: format(new Date(), utils.dateFormat),
      updatedby: user.user_id,
    };

    async function putData() {
      await apiGatwayCall(user.token, updDistData, 'updatedistributor')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setIsUpdate(true);
            handleAlertClick('Distributor updated successfully.', 'success');
          } else if (statusCode === 503) {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    putData();
    setOpen(false);
  };

  // GET request to fetch a Distributor
  const handleEditDistributor = (dId) => {
    setIsEditSearch(true);
    async function getData() {
      await apiGatwayCall(user.token, { id: dId }, 'getdistributor')
        .then((response) => {
          const { data, message, statusCode } = response.data;
          if (statusCode === 200) {
            setDistributor({
              country: data[0].country_id,
              name: data[0].name,
              distributorId: data[0].distributor_id,
            });
            setIsEditSearch(false);
            setOpen(true);
          } else {
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    getData();
    setIsUpdate(true);
  };

  // POST request to delete a Distributor
  const handleDeleteDistributor = (delDistId) => {
    const delDistData = {
      id: delDistId,
      updatedBy: user.user_id,
      updatedAt: format(new Date(), utils.dateFormat),
    };

    async function deleteData() {
      await apiGatwayCall(user.token, delDistData, 'deletedistributor')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setIsUpdate(true);
            handleAlertClick('Distributor removed successfully.', 'success');
          } else {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    deleteData();
  };

  // GET request to fetch all Distributors
  useEffect(() => {
    async function getDistributor() {
      await apiGatwayCall(
        user.token,
        { countryid: country },
        'getdistributorList'
      )
        .then((response) => {
          const { data, message, statusCode } = response.data;
          if (statusCode === 200) {
            const rows = data.map((d) => ({
              Id: d.distributor_id,
              data: [d.name],
            }));
            setTableRows(rows);
          } else if (statusCode === 404) {
            setTableRows([]);
          } else {
            handleAlertClick(message, 'error');
          }
          setIsSearching(false);
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    if (isUpdate) {
      getDistributor();
      setIsUpdate(false);
    }
  }, [isUpdate, country]);

  // GET request to fetch all Countries from Country table
  useEffect(() => {
    async function getCountries() {
      const countryData = await getEnum(user.token, ['country']);
      const ctr = countryData.country.map((c) => ({
        id: c.country_id,
        name: c.name,
      }));
      setAllCountries(ctr);
      setIsLoading(false);
    }
    getCountries();
  }, []);

  function SubmitAddButton() {
    if (distributor.country && distributor.name.length >= 3) {
      return (
        <Button
          variant='contained'
          onClick={
            distributor.distributorId === ''
              ? handleAddDistributor
              : handleUpdateDistributor
          }
        >
          Save Changes
        </Button>
      );
    }
    return (
      <Tooltip
        TransitionComponent={Zoom}
        arrow
        placement='top'
        title='Fill mandatory fields to enable'
        enterTouchDelay={0}
      >
        <span>
          <Button variant='contained' disabled>
            Save Changes
          </Button>
        </span>
      </Tooltip>
    );
  }

  return (
    <div>
      {isLoading ? (
        <BackdropLoader show={isLoading} />
      ) : (
        <div>
          <BackdropLoader show={isEditSearch} />
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleAlertClose}
          >
            <Alert onClose={handleAlertClose} severity={alert.type}>
              {alert.message}
            </Alert>
          </Snackbar>
          <Grid container item xs={12}>
            <h2 className='product-title'>Distributor List</h2>
          </Grid>
          <Grid container direction='row'>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <label className='form-label' htmlFor='country'>
                Country
              </label>
              <SelectFilter
                id='country'
                name='country'
                selected={country}
                getSelected={handleSearchCountry}
                minWidth='100%'
                optionList={allCountries}
              />
            </Grid>
            <Grid container item xs={12} sm={9} md={9} lg={9} justifyContent='flex-end'>
              <Button
                onClick={handleModalOpen}
                className='form-btn'
                size='medium'
                color='success'
                variant='contained'
                startIcon={<AddIcon size='small' />}
              >
                Add New Distributor
              </Button>
            </Grid>
          </Grid>
          <Modal
            open={open}
            onClose={(_, reason) => {
              if (reason !== 'backdropClick') {
                handleModalClose();
              }
            }}
            aria-labelledby='parent-modal-title'
            aria-describedby='parent-modal-description'
          >
            <div>
              <Box className='product_modal_box' sx={{ width: 500 }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <h4 className='modaltitle'>
                    {distributor.distributorId === ''
                      ? 'Create New Distributor'
                      : 'Edit Distributor'}
                  </h4>
                  <Button onClick={handleModalClose}>
                    <ClearIcon />
                  </Button>
                </div>
                <Box
                  component='form'
                  noValidate
                  autoComplete='off'
                  sx={{ p: 3 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Labels align='right' variant='subtitle1'>
                        Country
                        <Asterisk>*</Asterisk>
                      </Labels>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={6}>
                      <SelectFilter
                        selected={distributor.country}
                        minWidth='100%'
                        name='country'
                        optionList={allCountries}
                        getSelected={handleFormCountry}
                        disabled={distributor.distributorId !== ''}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Labels align='right' variant='subtitle1'>
                        Name
                        <Asterisk>*</Asterisk>
                      </Labels>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={6}>
                      <TextField
                        sx={{ width: '100%' }}
                        id='distributorName'
                        size='small'
                        name='name'
                        value={distributor.name}
                        onChange={handleOnChange}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <div className='buttondiv_productform'>
                  <Button variant='outlined' onClick={handleModalClose}>
                    Cancel
                  </Button>
                  <SubmitAddButton />
                </div>
              </Box>
            </div>
          </Modal>

          <RegistersTable
            searching={isSearching}
            register={distributorData}
            rows={tableRows}
            handleEdit={handleEditDistributor}
            handleDelete={handleDeleteDistributor}
            isDelete={user.role !== 'ADMIN'}
          />
        </div>
      )}
    </div>
  );
}
