import { Backdrop, CircularProgress } from '@mui/material';

export default function BackdropLoader(props) {
  const { show } = props;

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={show}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
}