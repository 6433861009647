import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  AppBar,
  Box,
  Button,
  Divider,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import '../Styles/Navbar.css';
import ListItemIcon from '@mui/material/ListItemIcon';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';
import logo from '../Images/lilly_logo.png';
import { useAuthContext } from './Auth/authContext';

const useStyles = makeStyles({
  bar: {
    color: 'white',
    fontSize: '30px',
    height: '47px',
  },
  blackBar: {
    color: '#c80000',
    fontWeight: 'lighter',
    fontSize: '30px',
    height: '47px',
  },
  active: {
    borderBottom: '2px solid #fff !important',
    borderTopLeftRadius: '4px !important',
    borderTopRightRadius: '4px !important',
    borderBottomLeftRadius: '0 !important',
    borderBottomRightRadius: '0 !important',
  },
});

const NavButton = styled(Button)({
  background: '#d52b1e',
  border: 0,
  borderRadius: 2,
  color: 'white',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(246, 247, 248, 0.3) !important',
    borderRadius: '2px !important',
  },
});

const paperprops = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      paddingLeft: '4px',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function MenuAppBar() {
  const classes = useStyles();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [settingAnchorEl, setSettingAnchorEl] = useState(null);
  const settingOpen = Boolean(settingAnchorEl);
  const [registersAnchorEl, setRegistersAnchorEl] = useState(null);
  const registersOpen = Boolean(registersAnchorEl);
  const { user, logout } = useAuthContext();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const settingHandleClick = (event) => {
    setSettingAnchorEl(event.currentTarget);
  };
  const settingHandleClose = () => {
    setSettingAnchorEl(null);
  };

  const registersHandleClick = (event) => {
    setRegistersAnchorEl(event.currentTarget);
  };

  const registersHandleClose = () => {
    setRegistersAnchorEl(null);
  };

  const handleLogout = () => {
    window.sessionStorage.clear();
    window.localStorage.clear();
    logout();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar sx={{ backgroundColor: '#d52b1e' }}>
        <Toolbar sx={{ backgroundColor: '#d52b1e' }}>
          <Link to='/Dashboard' className='logo-link'>
            <img alt='' src={logo} />
          </Link>
          <div className={classes.bar}>|</div>
          <Typography
            variant='subtitle1'
            component='div'
            sx={{ flexGrow: 1, pl: 2 }}
          >
            SEE Donation App
          </Typography>
          <div className={classes.blackBar}>|</div>

          <Link
            to='/Dashboard'
            style={{
              textDecoration: 'none',
              color: 'white',
              paddingRight: '0',
            }}
          >
            <NavButton
              variant='contained'
              disableElevation
              className={
                location.pathname === '/Dashboard' ? classes.active : ''
              }
            >
              Donation Requests
            </NavButton>
          </Link>

          <div className={classes.blackBar}>|</div>

          <div>
            <NavButton
              id='top-nav-bar-button'
              aria-controls='top-nav-bar-menu'
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              className={
                location.pathname.includes('/register') ? classes.active : ''
              }
              disableElevation
              onClick={registersHandleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Registers
            </NavButton>
            <StyledMenu
              id='top-nav-bar-menu'
              MenuListProps={{
                'aria-labelledby': 'top-nav-bar-button',
              }}
              anchorEl={registersAnchorEl}
              open={registersOpen}
              onClose={registersHandleClose}
            >
              {user.role === 'ADMIN' && (
                <div>
                  <Link
                    style={{ textDecoration: 'none', color: 'black' }}
                    to='/registers/products'
                  >
                    <MenuItem
                      style={{ textDecoration: 'none' }}
                      onClick={registersHandleClose}
                      disableRipple
                    >
                      Products
                    </MenuItem>
                  </Link>
                  <Divider sx={{ my: 0.5 }} />
                  <Link
                    to='/registers/HCOs'
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <MenuItem onClick={registersHandleClose} disableRipple>
                      HCOs
                    </MenuItem>
                  </Link>
                  <Divider sx={{ my: 0.5 }} />
                </div>
              )}

              <Link
                to='/registers/distributors'
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <MenuItem onClick={registersHandleClose} disableRipple>
                  Distributors
                </MenuItem>
              </Link>
            </StyledMenu>
          </div>
          <div className={classes.blackBar}>|</div>
          {user.role === 'ADMIN' && (
            <>
              <NavButton
                id='top-nav-bar-button'
                aria-controls='top-nav-bar-menu'
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                className={
                  location.pathname.includes('/settings') ? classes.active : ''
                }
                disableElevation
                sx={{ textTransform: 'none' }}
                onClick={settingHandleClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Settings
              </NavButton>
              <StyledMenu
                id='top-nav-bar-menu'
                MenuListProps={{
                  'aria-labelledby': 'top-nav-bar-button',
                }}
                anchorEl={settingAnchorEl}
                open={settingOpen}
                onClose={settingHandleClose}
              >
                <Link
                  style={{ textDecoration: 'none', color: 'black' }}
                  to='/settings/Alarm'
                >
                  <MenuItem onClick={settingHandleClose} disableRipple>
                    Alarm
                  </MenuItem>
                </Link>
                <Divider sx={{ my: 0.5 }} />
                <Link
                  style={{ textDecoration: 'none', color: 'black' }}
                  to='/settings/CurrencyList'
                >
                  <MenuItem onClick={settingHandleClose} disableRipple>
                    Currency List
                  </MenuItem>
                </Link>
                <Divider sx={{ my: 0.5 }} />
                <Link
                  style={{ textDecoration: 'none', color: 'black' }}
                  to='/settings/AccountMgmt'
                >
                  <MenuItem onClick={settingHandleClose} disableRipple>
                    Account management
                  </MenuItem>
                </Link>
              </StyledMenu>
              <div className={classes.blackBar}>|</div>
            </>
          )}
          <IconButton onClick={handleClick} className='btn' size='large'>
            <MoreVertIcon sx={{ color: 'white' }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={paperprops}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem>
              <ListItemText primary={user.user_name} secondary={user.role} />
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize='small' />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
}