import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Button,
  TextField,
  Grid,
  Tooltip,
  Zoom,
  Box,
  useMediaQuery,
} from '@mui/material';
import { subYears, add } from 'date-fns';
import SelectFilter from '../Components/UI/SelectFilter';
import '../Styles/Products.css';
import DateUI from '../Components/UI/DateUI';
import { Asterisk, Labels } from '../Components/UI/Common';

const useStyles = makeStyles(() => ({
  gridPadding: {
    paddingTop: '12px',
  },
  justifyFieldGrid: {
    justifyContent: 'center',
  },
  selectGrid: {
    paddingLeft: '24px !important',
    paddingTop: '10px !important',
  },
  textField: {
    width: '85% !important',
  },
  grid: {
    paddingTop: '0% !important',
  },
}));

const defaultHCO = {
  organizationId: '',
  name: '',
  code: '',
  countryId: 1,
  localName: '',
  alias: '',
  address: '',
  description: '',
  postOffice: '',
  city: '',
  agreementDate: new Date(),
  personName: '',
  personTitle: '',
  HCOInstitutionalNo: '',
  HCONationalNo: '',
};

function HCOform(props) {
  const classes = useStyles();
  const match = useMediaQuery('(max-width: 1000px)');
  const [HCO, setHCO] = useState(defaultHCO);
  const {
    editHCO,
    countries,
    content,
    getHCOData,
    updateHCOData,
    handleClose,
  } = props;
  const doubleCharPadding = match ? '6px' : '12px';

  const handleChangeDateCountry = (value, name) => {
    setHCO((prevState) => {
      const field = prevState;
      field[name] = value;
      return {
        ...field,
      };
    });
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setHCO((prevState) => {
      const field = prevState;
      field[name] = value;
      return {
        ...field,
      };
    });
  };

  useEffect(() => {
    setHCO({ ...editHCO });
  }, []);

  const handleClickSave = () => {
    const addHCOData = {
      organizationId: HCO.organizationId,
      name: HCO.name,
      code: HCO.code,
      countryId: HCO.countryId,
      localName: HCO.localName,
      alias: HCO.alias,
      address: HCO.address,
      description: HCO.description,
      postOffice: HCO.postOffice,
      city: HCO.city,
      agreementDate: HCO.agreementDate,
      personName: HCO.personName,
      personTitle: HCO.personTitle,
      HCOInstitutionalNo: HCO.HCOInstitutionalNo,
      HCONationalNo: HCO.HCONationalNo,
    };
    if (
      HCO.organizationId === '' ||
      editHCO.countryId !== addHCOData.countryId
    ) {
      getHCOData(addHCOData);
    } else {
      updateHCOData(addHCOData);
    }
    handleClose();
  };

  function SubmitButton() {
    if (
      HCO.name.length >= 3 &&
      HCO.localName.length >= 3 &&
      HCO.address.length >= 3 &&
      HCO.postOffice.length >= 3 &&
      HCO.agreementDate &&
      HCO.personName.length >= 3 &&
      HCO.personTitle.length >= 3 &&
      HCO.city.length >= 3 &&
      HCO.code.length >= 3
    ) {
      return (
        <Button onClick={handleClickSave} variant='contained'>
          Save Changes
        </Button>
      );
    } else {
      return (
        <Tooltip
          TransitionComponent={Zoom}
          arrow
          placement='top'
          title='Fill mandatory fields to enable'
          enterTouchDelay={0}
        >
          <span>
            <Button variant='contained' disabled>
              Save Changes
            </Button>
          </span>
        </Tooltip>
      );
    }
  }

  return (
    <div>
      <Box
        component='form'
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete='off'
      >
        <Grid container>
          <Grid container columnSpacing={2}>
            <Grid item xs={2} classes={{ root: classes.gridPadding }}>
              <Labels align='right' variant='subtitle1' gutterBottom>
                Country<Asterisk>*</Asterisk>
              </Labels>
            </Grid>
            <Grid item xs={4} classes={{ root: classes.selectGrid }}>
              <SelectFilter
                minWidth='87%'
                name='countryId'
                optionList={countries}
                disabled={!!HCO.isDisabled}
                selected={HCO.countryId}
                getSelected={handleChangeDateCountry}
              />
            </Grid>

            <Grid item xs={2} classes={{ root: classes.gridPadding }}>
              <Labels align='right' variant='subtitle1' gutterBottom>
                City<Asterisk>*</Asterisk>
              </Labels>
            </Grid>
            <Grid item xs={4}>
              <TextField
                classes={{ root: classes.textField }}
                id='city'
                size='small'
                name='city'
                value={HCO.city}
                helperText={content.helperText}
                onChange={handleOnChange}
              />
            </Grid>
          </Grid>

          <Grid container item columnSpacing={2}>
            <Grid item xs={2} classes={{ root: classes.gridPadding }}>
              <Labels align='right' variant='subtitle1' gutterBottom>
                Name<Asterisk>*</Asterisk>
              </Labels>
            </Grid>
            <Grid item xs={4}>
              <TextField
                classes={{ root: classes.textField }}
                id='name'
                size='small'
                name='name'
                value={HCO.name}
                helperText={content.helperText}
                onChange={handleOnChange}
              />
            </Grid>

            <Grid item xs={2} classes={{ root: classes.gridPadding }}>
              <Labels align='right' variant='subtitle1' gutterBottom>
                Post Office<Asterisk>*</Asterisk>
              </Labels>
            </Grid>
            <Grid item xs={4}>
              <TextField
                classes={{ root: classes.textField }}
                id='postOffice'
                size='small'
                name='postOffice'
                value={HCO.postOffice}
                helperText={content.helperText}
                onChange={handleOnChange}
              />
            </Grid>
          </Grid>

          <Grid container item columnSpacing={2}>
            <Grid item xs={2} classes={{ root: classes.gridPadding }}>
              <Labels align='right' variant='subtitle1' gutterBottom>
                Local Name<Asterisk>*</Asterisk>
              </Labels>
            </Grid>
            <Grid item xs={4}>
              <TextField
                classes={{ root: classes.textField }}
                id='localName'
                size='small'
                name='localName'
                value={HCO.localName}
                helperText={content.helperText}
                onChange={handleOnChange}
              />
            </Grid>

            <Grid item xs={2} classes={{ root: classes.gridPadding }}>
              <Labels align='right' variant='subtitle1' gutterBottom>
                Person<Asterisk>*</Asterisk>
              </Labels>
            </Grid>
            <Grid item xs={4}>
              <TextField
                classes={{ root: classes.textField }}
                id='personName'
                size='small'
                name='personName'
                value={HCO.personName}
                helperText={content.helperText}
                onChange={handleOnChange}
              />
            </Grid>
          </Grid>

          <Grid container item columnSpacing={2}>
            <Grid item xs={2} classes={{ root: classes.gridPadding }}>
              <Labels align='right' variant='subtitle1' gutterBottom>
                Alias
              </Labels>
            </Grid>
            <Grid item xs={4}>
              <TextField
                classes={{ root: classes.textField }}
                id='alias'
                size='small'
                name='alias'
                value={HCO.alias}
                onChange={handleOnChange}
              />
            </Grid>

            <Grid item xs={2} classes={{ root: classes.gridPadding }}>
              <Labels align='right' variant='subtitle1' gutterBottom>
                Title<Asterisk>*</Asterisk>
              </Labels>
            </Grid>
            <Grid item xs={4}>
              <TextField
                classes={{ root: classes.textField }}
                id='personTitle'
                size='small'
                name='personTitle'
                value={HCO.personTitle}
                helperText={content.helperText}
                onChange={handleOnChange}
              />
            </Grid>
          </Grid>

          <Grid container item columnSpacing={2}>
            <Grid item xs={2} classes={{ root: classes.gridPadding }}>
              <Labels align='right' variant='subtitle1' gutterBottom>
                Code<Asterisk>*</Asterisk>
              </Labels>
            </Grid>
            <Grid item xs={4}>
              <TextField
                classes={{ root: classes.textField }}
                id='code'
                size='small'
                name='code'
                value={HCO.code}
                helperText={content.helperText}
                onChange={handleOnChange}
              />
            </Grid>

            <Grid item xs={2} sx={{ pt: doubleCharPadding }}>
              <Labels align='right' variant='subtitle1' gutterBottom>
                Agreement Date<Asterisk>*</Asterisk>
              </Labels>
            </Grid>
            <Grid item xs={4}>
              <DateUI
                minDate={subYears(new Date(), 20)}
                maxDate={add(new Date(), { years: 20 })}
                width='85% !important'
                getDate={handleChangeDateCountry}
                name='agreementDate'
                defaultValue={HCO.agreementDate}
              />
            </Grid>
          </Grid>

          <Grid container item columnSpacing={2}>
            <Grid item xs={2} classes={{ root: classes.gridPadding }}>
              <Labels align='right' variant='subtitle1' gutterBottom>
                Address<Asterisk>*</Asterisk>
              </Labels>
            </Grid>
            <Grid item xs={4}>
              <TextField
                classes={{ root: classes.textField }}
                id='address'
                size='small'
                name='address'
                value={HCO.address}
                helperText={content.helperText}
                onChange={handleOnChange}
              />
            </Grid>

            <Grid item xs={2} classes={{ root: classes.gridPadding }}>
              <Labels align='right' variant='subtitle1' gutterBottom>
                Description
              </Labels>
            </Grid>
            <Grid item xs={4}>
              <TextField
                classes={{ root: classes.textField }}
                id='description'
                size='small'
                name='description'
                value={HCO.description}
                onChange={handleOnChange}
              />
            </Grid>
          </Grid>

          <Grid container item columnSpacing={2}>
            <Grid item xs={2} sx={{ pt: doubleCharPadding }}>
              <Labels align='right' variant='subtitle1' gutterBottom>
                National Number
              </Labels>
            </Grid>
            <Grid item xs={4}>
              <TextField
                classes={{ root: classes.textField }}
                id='HCONationalNo'
                size='small'
                name='HCONationalNo'
                value={HCO.HCONationalNo}
                onChange={handleOnChange}
              />
            </Grid>

            <Grid item xs={2} classes={{ root: classes.gridPadding }}>
              <Labels align='right' variant='subtitle1' gutterBottom>
                Inst. Number
              </Labels>
            </Grid>
            <Grid item xs={4}>
              <TextField
                classes={{ root: classes.textField }}
                id='HCOInstitutionalNo'
                size='small'
                name='HCOInstitutionalNo'
                value={HCO.HCOInstitutionalNo}
                onChange={handleOnChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <div className='buttondiv_productform'>
        <Button variant='outlined' onClick={handleClose}>
          Cancel
        </Button>
        <SubmitButton />
      </div>
    </div>
  );
}

HCOform.propTypes = {
  editHCO: PropTypes.instanceOf(Object).isRequired,
  content: PropTypes.any.isRequired,
  countries: PropTypes.instanceOf(Array).isRequired,
  handleClose: PropTypes.func.isRequired,
  getHCOData: PropTypes.func.isRequired,
  updateHCOData: PropTypes.func,
};

HCOform.defaultProps = {
  updateHCOData: () => {},
};

export default HCOform;
