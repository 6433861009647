import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Paper, Typography, Button } from '@mui/material';
import authProvider from './authProvider';
import AuthNav from './authNav';

export default function Logout() {
  const [show, setShow] = useState(false);
  const history = useHistory();
  const path = window.location.pathname.replace('logout', 'login');

  useEffect(async () => {
    const isLogged = await authProvider.isLoggedIn();
    isLogged ? history.replace('/Dashboard') : setShow(true);
  });

  return (
    <div>
      {show ? (
        <>
          <AuthNav />
          <Paper
            elevation={3}
            sx={{
              m: '5%',
              p: '5%',
            }}
          >
            <Typography variant='h6' gutterBottom component='div'>
              You have been logged out of SEE Donation Managment Tool. <br />
              Click{' '}
              <Button
                sx={{ p: '6px 0', fontSize: '1.175rem' }}
                onClick={() => authProvider.login()}
              >
                here
              </Button>{' '}
              to Login again.
            </Typography>
          </Paper>
        </>
      ) : (
        ''
      )}
    </div>
  );
}
