import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Alert as Alt,
  Box,
  Button,
  Chip,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Grid,
  Input,
  InputLabel,
  Modal,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import SaveIcon from '@mui/icons-material/Save';
import { subYears, add, format } from 'date-fns';
import axios from 'axios';
import { useAuthContext } from '../Components/Auth/authContext';
import {
  Alert,
  Asterisk,
  CustomDialog,
  Labels,
  checkFormat,
} from '../Components/UI/Common';
import BackdropLoader from '../Components/UI/BackdropLoader';
import DateUI from '../Components/UI/DateUI';
import RegistersTable from '../Components/UI/RegistersTable';
import SelectFilter from '../Components/UI/SelectFilter';
import getEnum from '../Components/Utils/Enum';
import { apiGatwayCall, getAll } from '../Components/Utils/utils';
import HCOform from './HCOform';
import Productform from './Productform';
import AddDonationProduct from './AddDonationProduct';
import { returnDialog, donationProducts, utils, HCOsData } from '../Data';
import '../Styles/Products.css';

const defaultHCO = {
  organizationId: '',
  name: '',
  code: '',
  countryId: 1,
  localName: '',
  alias: '',
  address: '',
  description: '',
  postOffice: '',
  city: '',
  agreementDate: new Date(),
  personName: '',
  personTitle: '',
  HCOInstitutionalNo: '',
  HCONationalNo: '',
  isDisabled: true,
};

const defaultRequestProduct = {
  productId: -1,
  quantity: 1,
  quantityApproved: 0,
  quantityDonated: 0,
  expDate: '',
  lotSerialNo: '',
};

const defaultProduct = {
  productId: '',
  countryId: 1,
  enumCurrencyId: 166,
  code: '',
  name: '',
  brand: '',
  price: '',
  dosage: '',
  typeOfApplication: '',
  localTypeOfApplication: '',
  description: '',
  isDisabled: true,
};

const useStyles = makeStyles(() => ({
  textFieldMultiline: {
    width: '94.5%',
  },
  textField: {
    width: '85%',
  },
  gridHeight: {
    height: '70px',
  },
}));

const defaultError = {
  HCO: '',
  code: 'Must be at-least 3 chars',
  isCodeError: false,
  enumRequestStatusId: '',
  dateRequest: '',
  dateDonation: '',
  approvalNumber: '',
  dateApproval: '',
  dateSeeCommittee: '',
  status: '',
};

const getEnumData = ['enum_request_status', 'enum_currency', 'country'];
const getTableData = ['distributor', 'organization', 'product'];

function CreateDonationRequest() {
  const [addProductModalOpen, setAddProductModalOpen] = useState(false);
  const [createProductModalOpen, setCreateProductModalOpen] = useState(false);
  const [isRequestIDGenerated, setBoolean] = useState(false);
  const [HCOModalOpen, setHCOModalOpen] = useState(false);
  const [HCO, setHCO] = useState(defaultHCO);
  const [product, setProduct] = useState(defaultProduct);
  const [reqProduct, setReqProduct] = useState(defaultRequestProduct);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(defaultError);
  const [isCreate, setIsCreate] = useState(false);
  const [isResetTables, setIsResetTables] = useState(false);
  const [requestProducts, setRequestProducts] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isAlarmCheck, setIsAlarmCheck] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [alarmErr, setAlarmErr] = useState('');
  const [tableRows, setTableRows] = useState([]);
  const [staticData, setStaticData] = useState({});
  const [alert, setAlert] = useState({ message: '', type: '' });
  const [alertOpen, setAlertOpen] = useState(false);
  const [requestData, setRequestData] = useState({});
  const [selFiles, setSelFiles] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [clickedChipIndex, setClickedChipIndex] = useState(null);
  const [doDelete, setDoDelete] = useState(false);
  const [deleteFile, setDeleteFile] = useState('');
  const [fileSizeError, setSizeError] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEditSearch, setIsEditSearch] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const classes = useStyles();
  const { user } = useAuthContext();
  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(search);
  let reqId = '';
  reqId = params.get('reqId');

  const handleAlertClick = (message, type) => {
    setAlert({
      message,
      type,
    });
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  //function to choose a file for S3 upload and file validations
  const handleFileSelect = (e) => {
    const { files } = e.target;
    const { file } = requestData;
    const fileNameExists = file
      ?.map(({ file_name }) => file_name)
      .includes(files[0].name);

    if (files[0].size === 0 || files[0].size > 52428800) {
      setSelFiles(null);
      setSizeError(
        `File -'${files[0].name}' exceeds size of 50MB or is empty.`
      );
    } else if (requestData.file && fileNameExists) {
      setSelFiles(null);
      setSizeError(
        `File - '${files[0].name}' with same name already exists, please rename and upload.`
      );
    } else {
      setSizeError('');
      setSelFiles(files[0]);
    }
  };

  //function to get signed url for file upload to S3
  const handleUploadFile = async () => {
    setIsUploading(true);
    let formData = {
      fileName: selFiles.name,
      fileType: selFiles.type,
      orgReqId: requestData.requestId,
      updatedBy: user.user_id,
      createdAt: format(new Date(), utils.dateFormat),
    };
    await apiGatwayCall(user.token, formData, 'getsignedUrlPut')
      .then(async (response) => {
        const { data, statusCode, message } = response.data;
        if (statusCode === 200) {
          formData = {
            ...formData,
            file: selFiles,
            url: data.url,
            key: data.key
          }
          await handleUploadToS3(formData); 
        } else {
          handleAlertClick(message, 'error');
          setIsUploading(false);
        }
      })
      .catch(() => {
        handleAlertClick(utils.errorMessage, 'error');
        setIsUploading(false);
      });
  };

  //function to upload a file to S3
  async function handleUploadToS3(formData) {
    await axios({
      method: 'put',
      url: formData.url,
      data: formData.file,
      headers: { 'Content-Type': formData.fileType },
    }).then(async (res) => {
      const { url, file, ...newFormData }  = formData; 
      newFormData.etag = res?.headers?.etag;
      await apiGatwayCall(user.token, newFormData, 'makedbEntryS3Put')
        .then((response) => {
          const { data, message, statusCode } = response.data;
          if(statusCode === 200) {
            const file = data.file.filter(f => Object.keys(f).length > 0);
            setRequestData({ ...requestData, file: file });
            setSelFiles(null);
            handleAlertClick('File uploaded successfully.', 'success');
          } else {
            handleAlertClick(message, 'error');
          }
          setIsUploading(false);
      }); 
    }).catch((err) => {
      handleAlertClick(utils.errorMessage, 'error');
      setIsUploading(false);
    });
  }

  //function to get signed url for file download from S3
  const handleDownloadFile = async (file, index) => {
    setClickedChipIndex(index);
    handleAlertClick('Checking file status and downloading.', 'info');
    const formData = {
      file: file,
      orgReqId: requestData.requestId,
      updatedBy: user.user_id,
    };
    await apiGatwayCall(user.token, formData, 'getsignedUrlGet')
      .then(async (response) => {
        const { data, message, statusCode } = response.data;
        if (statusCode === 200) {
          formData.url = data;
          handleDownloadFromS3(formData)
        } else {
          handleAlertClick(message, 'error');
        }
      })
      .catch(() => {
        setClickedChipIndex(null);
        handleAlertClick(utils.errorMessage, 'error');
      });
  };

  //function to download a file from S3
  async function handleDownloadFromS3(formData) {
    await axios({
      method: 'get',
      url: formData.url,
      headers: { 'Content-Type': formData.file.file_type },
    })
    .then(() => {
      const link = document.createElement('a');
      link.setAttribute('href', formData.url);
      link.setAttribute('download', formData.file.file_name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      handleAlertClick('File downloaded successfully.', 'success');
    })
    .catch(async () => {
      const { url, ...newFormData } = formData;
      await makedbEntry404(newFormData, 'File does not exist.', 'error');
    });
    setClickedChipIndex(null);
  }

  //function to delete file entry when file not found or deleting a file from S3
  async function makedbEntry404(formData, message, type) {
    await apiGatwayCall(user.token, formData, 'makedbEntryS3404')
        .then((response) => {
          const { data } = response.data;
          handleAlertClick(message, type);
          const file = data.file.filter((f) => Object.keys(f).length > 0);
          setRequestData({ ...requestData, file: file });
        })
  }

  //function to initiate delete file from S3 action
  const handleDeleteFile = async () => {
    setDoDelete(false);
    handleAlertClick('Checking file status and deleting.', 'info');
    const formData = {
      file: deleteFile,
      orgReqId: requestData.requestId,
      updatedBy: user.user_id,
    };
    await handleCheckFileExists(formData);
  };

  //function to check if file exists on S3 before deleting
  async function handleCheckFileExists(formData) {
      await apiGatwayCall(user.token, formData, 'getsignedUrlGet')
        .then(async (response) => {
          const { data, message, statusCode } = response.data;
          if (statusCode === 200) {
            await axios({
              method: 'get',
              url: data,
              headers: { 'Content-Type': formData.file.file_type },
            })
            .then(async () => {
              await handleDeleteFromS3(formData);
            })
            .catch(async () => {
              await makedbEntry404(formData, 'File does not exist.', 'error');
              setClickedChipIndex(null);
            });
          } else {
            handleAlertClick(message, 'error');
            setClickedChipIndex(null);
          }
        })
        .catch((err) => {
          handleAlertClick(utils.errorMessage, 'error');
          setClickedChipIndex(null);
        });
  }

  //function to delete a file from S3 
  async function handleDeleteFromS3(formData) {
    await apiGatwayCall(user.token, formData, 'getsignedUrlDelete')
      .then(async (response) => {
        const { data, message, statusCode } = response.data;
        if (statusCode === 200) {
          await axios({
            method: 'delete',
            url: data,
            headers: { 'Content-Type': formData.file.file_type },
          })
          .then(async () => {
            await makedbEntry404(formData, 'File deleted successfully.', 'success');
          });
        } else {
          handleAlertClick(message, 'error');
        }
      });
    setClickedChipIndex(null);
  }

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name } = e.target;
    const { value } = e.target;
    setRequestData((prevState) => {
      const field = prevState;
      field[name] = value;
      return {
        ...field,
      };
    });
    if (name === 'code' || name === 'approvalNumber') {
      setIsCheck(true);
    }
  };

  const handleChangeSelect = (value, name) => {
    setRequestData((prevState) => {
      const field = prevState;
      field[name] = value;
      return {
        ...field,
      };
    });
    if (name === 'countryId') {
      setIsResetTables(true);
    }
    if (
      name === 'dateRequest' ||
      name === 'requestStatusId' ||
      name === 'dateSeeCommittee' ||
      name === 'dateApproval' ||
      name === 'dateDonation' ||
      name === 'requestStatusId' ||
      name === 'HCO'
    ) {
      setIsCheck(true);
    }
  };

  const handleCreateProductModal = () => {
    setProduct({ ...defaultProduct, countryId: requestData.countryId });
    setCreateProductModalOpen(!createProductModalOpen);
  };

  const handleAddProductModal = () => {
    setReqProduct(defaultRequestProduct);
    setAddProductModalOpen(!addProductModalOpen);
  };

  const handleHCOModal = () => {
    setHCO({ ...defaultHCO, countryId: requestData.countryId });
    setHCOModalOpen(!HCOModalOpen);
  };

  // POST request to add Donation Request to database
  const handleAddDonation = () => {
    const addOrgReqData = {
      organizationId: requestData.HCO,
      name: '',
      code: requestData.code,
      countryId: requestData.countryId,
      comment: requestData.comment,
      reportToHub: 0,
      enumRequestStatusId: requestData.requestStatusId,
      dateRequest: requestData.dateRequest,
      dateDonation: requestData.dateDonation,
      approvalNumber: requestData.approvalNumber,
      dateApproval: requestData.dateApproval,
      distributorId: requestData.distributorId,
      createdBy: user.user_id,
      updatedBy: user.user_id,
      createdAt: format(new Date(), utils.dateFormat),
      dateSeeCommittee: requestData.dateSeeCommittee,
    };
    async function add() {
      await apiGatwayCall(user.token, addOrgReqData, 'addorganizationRequest')
        .then((response) => {
          const { data, message, statusCode } = response.data;
          if (statusCode === 200) {
            handleEditDonation(data.rows[0].organization_request_id);
            setIsUpdate(true);
            handleAlertClick('Donation Request added successfully.', 'success');
          } else {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    add();
  };

  // PUT request to update a Donation Request
  const handleUpdateDonation = () => {
    const updOrgReqData = {
      id: requestData.requestId,
      organizationId: requestData.HCO,
      name: '',
      code: requestData.code,
      countryId: requestData.countryId,
      comment: requestData.comment,
      reportToHub: 0,
      enumRequestStatusId: requestData.requestStatusId,
      dateRequest: requestData.dateRequest,
      dateDonation: requestData.dateDonation,
      approvalNumber: requestData.approvalNumber,
      dateApproval: requestData.dateApproval,
      distributorId: requestData.distributorId,
      dateSeeCommittee: requestData.dateSeeCommittee,
      updatedAt: format(new Date(), utils.dateFormat),
      updatedBy: user.user_id,
    };

    async function putData() {
      await apiGatwayCall(
        user.token,
        updOrgReqData,
        'updateorganizationRequest'
      )
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            handleAlertClick(
              'Donation Request updated successfully.',
              'success'
            );
            handleCheckAlarm(requestData.requestId);
          } else {
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    putData();
  };

  // GET request to fetch single Donation Request data
  const handleEditDonation = (orgReqId) => {
    async function getData() {
      await apiGatwayCall(
        user.token,
        { id: orgReqId },
        'getorganizationRequest'
      )
        .then((response) => {
          const { data, message, statusCode } = response.data;
          if (statusCode === 200) {
            const file =
              data[0].file &&
              data[0]?.file.filter((f) => Object.keys(f).length > 0);
            setRequestData({
              requestId: data[0].organization_request_id,
              fullNumber: data[0].full_number,
              HCO: data[0].organization_id,
              countryId: data[0].country_id,
              code: data[0].code,
              name: data[0].name,
              comment: data[0].comment,
              requestStatusId: data[0].enum_request_status_id,
              dateRequest: data[0].date_request,
              dateDonation: data[0].date_donation,
              approvalNumber: data[0].approval_number,
              dateApproval: data[0].date_approval,
              distributorId: data[0].distributor_id,
              dateSeeCommittee: data[0].date_see_committee,
              file: file,
            });
            setBoolean(true);
            setIsUpdate(true);
          } else {
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    getData();
  };

  // GET request to check Alarm
  const handleCheckAlarm = (alarmId) => {
    async function getData() {
      await apiGatwayCall(user.token, { id: alarmId }, 'checkalarm')
        .then((response) => {
          const { data, message, statusCode } = response.data;
          if (statusCode === 200) {
            let alarm = data.replace(/!/g, '!\n');
            setAlarmErr(alarm);
          } else {
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    getData();
  };

  useEffect(() => {
    if (isCheck) {
      if (
        requestData.HCO !== -1 &&
        requestData.requestStatusId !== -1 &&
        !checkFormat(requestData.code, 3) &&
        requestData.code.length >= 3
      ) {
        if (requestData.dateRequest === null) {
          setIsCreate(false);
          setErrorMessage({
            ...defaultError,
            dateRequest: 'Please set Request Date',
          });
        } else if (
          requestData.dateApproval !== null &&
          requestData.dateSeeCommittee === null
        ) {
          setIsCreate(false);
          setErrorMessage({
            ...defaultError,
            dateSeeCommittee: 'Please set GD Committee Date',
          });
        } else if (
          requestData.dateDonation !== null &&
          requestData.dateApproval === null
        ) {
          setIsCreate(false);
          setErrorMessage({
            ...defaultError,
            dateApproval: 'Please set Approval Date',
          });
        } else {
          const approvedValues = requestProducts.map((p) => p.data[2] > 0);
          const isApproved = approvedValues.includes(true);
          const donatedValues = requestProducts.map((p) => p.data[3] > 0);
          const isDonated = donatedValues.includes(true);
          if (
            isApproved &&
            (requestData.dateApproval === null ||
              requestData.approvalNumber === '')
          ) {
            setIsCreate(false);
            setErrorMessage({
              ...defaultError,
              dateApproval:
                requestData.dateApproval === null
                  ? 'Please set Approval Date'
                  : '',
              approvalNumber:
                requestData.approvalNumber === ''
                  ? 'Please set Approval Number'
                  : '',
            });
            handleAlertClick(
              'There are approved quantity but the Approval number or Approval date is empty or invalid',
              'error'
            );
          } else if (isDonated && requestData.dateDonation === null) {
            setIsCreate(false);
            setErrorMessage({
              ...defaultError,
              dateDonation: 'Please set Donation Date',
            });
            handleAlertClick(
              'There are donated quantity but the donation date is empty or invalid',
              'error'
            );
          } else if (isDonated && requestData.requestStatusId !== 9) {
            setIsCreate(false);
            setErrorMessage({
              ...defaultError,
              status: 'Please select valid Status',
            });
            handleAlertClick(
              'There are some donated products within this request and status is not appropriate',
              'error'
            );
          } else {
            setErrorMessage({
              ...defaultError,
            });
            setIsCreate(true);
          }
        }
      } else {
        setIsCreate(false);
        if (requestData.code !== '' && checkFormat(requestData.code, 3)) {
          setErrorMessage({
            ...defaultError,
            code: 'Must not include special characters, hyphen(-) allowed',
            isCodeError: true,
          });
        } else if (requestData.code !== '' && requestData.code.length < 3) {
          setErrorMessage({
            ...defaultError,
            isCodeError: true,
          });
        } else {
          setErrorMessage({
            ...defaultError,
          });
        }
      }
      setIsCheck(false);
    }
  }, [isCheck]);

  // GET request to fetch Product list of a Donation Request
  useEffect(() => {
    async function getTable() {
      const orgReqID = reqId || requestData.requestId;
      await apiGatwayCall(
        user.token,
        { id: orgReqID },
        'getorganizationRequestProductList'
      )
        .then((response) => {
          const { data, message, statusCode } = response.data;
          if (statusCode === 200) {
            const rows = data.map((orp) => ({
              Id: orp.organization_request_product_id,
              data: [
                orp.name,
                orp.quantity,
                orp.quantity_approved,
                orp.quantity_donated,
              ],
            }));
            setTableRows(rows);
            setRequestProducts(rows);
            setIsCheck(true);
          } else if (statusCode === 404) {
            setTableRows([]);
          } else {
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    if (isUpdate) {
      getTable();
      setIsUpdate(false);
    }
  }, [isUpdate]);

  // GET request to fetch HCO, Product and Distributor
  useEffect(() => {
    async function getData() {
      const tableData = await getAll(user.token, [
        'organization',
        'product',
        'distributor',
      ]);
      let org = [];
      if (tableData.organization && tableData.organization.length > 0) {
        org = tableData.organization.map((e) => ({
          id: e.organization_id,
          name: e.name,
          countryId: e.country_id,
        }));
        org = org.filter(
          (h) => h.countryId === requestData.countryId || h.id === -1
        );
      }
      org.splice(0, 0, { id: -1, name: 'Select HCO' });

      let prd = [];

      if (tableData.product && tableData.product.length > 0) {
        prd = tableData.product.map((e) => ({
          id: e.product_id,
          name: e.name,
          countryId: e.country_id,
        }));

        prd = prd.filter(
          (h) => h.countryId === requestData.countryId || h.id === -1
        );
      }
      prd.splice(0, 0, { id: -1, name: 'Select Product' });

      let dtr = [];
      if (tableData.distributor && tableData.distributor.length > 0) {
        dtr = tableData.distributor.map((e) => ({
          id: e.distributor_id,
          name: e.name,
          countryId: e.country_id,
        }));

        dtr = dtr.filter(
          (h) => h.countryId === requestData.countryId || h.id === 0
        );
      }
      dtr.splice(0, 0, { id: 0, name: 'None' });
      setStaticData((prevState) => ({
        ...prevState,
        HCOs: org,
        products: prd,
        distributors: dtr,
      }));
      setIsResetTables(false);
    }
    getData();
  }, [isResetTables]);

  // GET request to fetch Country, Status, Period Represents data
  useEffect(() => {
    if (reqId) {
      handleEditDonation(reqId);
      setIsCreate(true);
    }
    setRequestData({
      requestId: null,
      fullNumber: '',
      HCO: -1,
      code: '',
      countryId: 1,
      comment: '',
      reportToHub: 0,
      requestStatusId: -1,
      dateRequest: new Date(),
      dateDonation: null,
      approvalNumber: null,
      dateApproval: null,
      distributorId: 0,
      dateSeeCommittee: null,
      file: null,
    });
    async function getData() {
      const tableData = await getAll(user.token, getTableData);
      const enumData = await getEnum(user.token, getEnumData);

      const enumStatus = enumData.enum_request_status.map((e) => ({
        id: e.enum_request_status_id,
        name: e.description,
      }));
      enumStatus.splice(0, 0, { id: -1, name: 'Select Status' });

      const enumCur = enumData.enum_currency.map((e) => ({
        id: e.enum_currency_id,
        name: e.code,
      }));

      let org = [];
      if (tableData.organization && tableData.organization.length > 0) {
        org = tableData.organization.map((e) => ({
          id: e.organization_id,
          name: e.name,
          countryId: e.country_id,
        }));
        org = org.filter((h) => h.countryId === 1 || h.id === -1);
      }
      org.splice(0, 0, { id: -1, name: 'Select HCO' });

      const ctr = enumData.country.map((e) => ({
        id: e.country_id,
        name: e.name,
      }));

      let prd = [];
      if (tableData.product && tableData.product.length > 0) {
        prd = tableData.product.map((e) => ({
          id: e.product_id,
          name: e.name,
          countryId: e.country_id,
        }));
        prd = prd.filter((h) => h.countryId === 1 || h.id === -1);
      }
      prd.splice(0, 0, { id: -1, name: 'Select Product' });

      let dtr = [];
      if (tableData.distributor && tableData.distributor.length > 0) {
        dtr = tableData.distributor.map((e) => ({
          id: e.distributor_id,
          name: e.name,
          countryId: e.country_id,
        }));
        dtr = dtr.filter((h) => h.countryId === 1 || h.id === 0);
      }
      dtr.splice(0, 0, { id: 0, name: 'None' });

      if (org && enumStatus && ctr && dtr) {
        setStaticData((prevState) => ({
          ...prevState,
          HCOs: org,
          countries: ctr,
          Status: enumStatus,
          distributors: dtr,
          products: prd,
          enumCurrency: enumCur,
        }));
        setIsResetTables(true);
        setIsLoading(false);
      }
    }
    getData();
  }, []);

  // GET request to check Alarm data is valid or not
  useEffect(() => {
    async function getData() {
      const tableData = await getAll(user.token, [
        'alarm',
        'organization_request_product',
        'organization_request',
      ]);
      setStaticData((prevState) => ({
        ...prevState,
        alarms: tableData.alarm,
        orgReqPrd: tableData.organization_request_product,
        orgReq: tableData.organization_request,
      }));
    }
    getData();
    setIsAlarmCheck(false);
  }, [isAlarmCheck]);

  // GET request to add Product(s) to a Donation Request
  const handleAddDonationProduct = (requestProductData) => {
    const addReqPrdData = {
      ...requestProductData,
      createdBy: user.user_id,
      updatedBy: user.user_id,
      createdAt: format(new Date(), utils.dateFormat),
    };
    async function add() {
      await apiGatwayCall(
        user.token,
        addReqPrdData,
        'addorganizationRequestProduct'
      )
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            reqId = response.data.organization_request_id;
            setIsUpdate(true);
            handleAlertClick('Donation Product added successfully.', 'success');
            setIsAlarmCheck(true);
          } else if (statusCode === 503) {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    add();
  };

  // GET request to fetch selected Product data in a Donation Request
  const handleEditProduct = (orgReqId) => {
    setIsEditSearch(true);
    async function getData() {
      await apiGatwayCall(
        user.token,
        { id: orgReqId },
        'getorganizationRequestProduct'
      )
        .then((response) => {
          const { data, message, statusCode } = response.data;
          if (statusCode === 200) {
            setReqProduct({
              id: data[0].organization_request_product_id,
              productId: data[0].product_id,
              quantity: data[0].quantity,
              quantityApproved: data[0].quantity_approved,
              quantityDonated: data[0].quantity_donated,
              expDate: data[0].expiration_date,
              lotSerialNo: data[0].lot_serial_no,
            });
            setIsEditSearch(false);
            setAddProductModalOpen(!addProductModalOpen);
          } else {
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    getData();
  };

  // PUT request to update a Product data in a Donation Request
  const handleUpdateDonationProduct = (uOrgReqPrd) => {
    const updORPrdData = {
      ...uOrgReqPrd,
      id: uOrgReqPrd.orgRequestProductId,
      updatedAt: format(new Date(), utils.dateFormat),
      updatedBy: user.user_id,
    };

    async function putData() {
      await apiGatwayCall(
        user.token,
        updORPrdData,
        'updateorganizationRequestProduct'
      )
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setIsUpdate(true);
            handleAlertClick(
              'Donation Product updated successfully.',
              'success'
            );
            setIsAlarmCheck(true);
          } else if (statusCode === 503) {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    putData();
  };

  // PUT request to delete a Product from a Donation Request
  const handleDeleteProduct = (delOrgReqPrdId) => {
    const delORPrdData = {
      id: delOrgReqPrdId,
      updatedBy: user.user_id,
      updatedAt: format(new Date(), utils.dateFormat),
    };
    async function deleteData() {
      await apiGatwayCall(
        user.token,
        delORPrdData,
        'deleteorganizationRequestProduct'
      )
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setIsUpdate(true);
            handleAlertClick(
              'Donation Product removed successfully.',
              'success'
            );
          } else {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    deleteData();
  };

  // PUT requst to add a HCO from Donation Request page(allowed)
  const handleAddHCO = (aHco) => {
    const addHcoData = {
      ...aHco,
      createdBy: user.user_id,
      updatedBy: user.user_id,
      createdAt: format(new Date(), utils.dateFormat),
    };

    async function add() {
      await apiGatwayCall(user.token, addHcoData, 'addorganization')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setHCOModalOpen(false);
            handleAlertClick('HCO added successfully.', 'success');
          } else if (statusCode === 503) {
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    add();
    setIsResetTables(true);
  };

  // PUT requst to add a Product from Donation Request page(allowed)
  const handleAddProduct = (aPrd) => {
    const addPrdData = {
      ...aPrd,
      createdBy: user.user_id,
      updatedBy: user.user_id,
      createdAt: format(new Date(), utils.dateFormat),
    };
    async function add() {
      await apiGatwayCall(user.token, addPrdData, 'addproduct')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setCreateProductModalOpen(false);
            setIsResetTables(true);
            handleAlertClick('Product added successfully.', 'success');
          } else if (statusCode === 503) {
            setIsResetTables(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    add();
  };

  const handleReturnClick = () => {
    setDialogOpen(!dialogOpen);
  };

  function SubmitButton() {
    if (
      !(
        isCreate &&
        !(staticData.HCOs.length === 1 || staticData.distributors.length === 1)
      )
    ) {
      return (
        <Tooltip
          TransitionComponent={Zoom}
          arrow
          placement='top'
          title='Fill mandatory fields to enable'
          enterTouchDelay={0}
        >
          <span>
            <Button
              className='form-btn'
              size='small'
              variant='contained'
              disabled
              color={isRequestIDGenerated ? 'primary' : 'success'}
              startIcon={<SaveIcon size='small' />}
            >
              {isRequestIDGenerated ? 'SAVE REQUEST' : 'CREATE REQUEST'}
            </Button>
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Button
          className='form-btn'
          size='small'
          variant='contained'
          color={isRequestIDGenerated ? 'primary' : 'success'}
          onClick={
            isRequestIDGenerated ? handleUpdateDonation : handleAddDonation
          }
          startIcon={<SaveIcon size='small' />}
        >
          {isRequestIDGenerated ? 'SAVE REQUEST' : 'CREATE REQUEST'}
        </Button>
      );
    }
  }

  return (
    <div>
      {isLoading ? (
        <BackdropLoader show={isLoading} />
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <BackdropLoader show={isEditSearch} />
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleAlertClose}
          >
            <Alert onClose={handleAlertClose} severity={alert.type}>
              {alert.message}
            </Alert>
          </Snackbar>
          <CustomDialog
            open={dialogOpen}
            handleCancel={handleReturnClick}
            handleOk={() => history.push('/Dashboard')}
            content={returnDialog}
          />
          <Grid container spacing={3}>
            <Grid container item xs={12}>
              <Typography display='inline' variant='h6' gutterBottom>
                {isRequestIDGenerated ? requestData.fullNumber : 'New Request'}
              </Typography>
            </Grid>

            <Grid container item spacing={3}>
              <Grid
                container
                item
                columnSpacing={4}
                classes={{ root: classes.gridHeight }}
              >
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Labels align='right' variant='subtitle1' gutterBottom>
                    Country
                    <Asterisk>*</Asterisk>
                  </Labels>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <SelectFilter
                    minWidth='85%'
                    maxWidth='85%'
                    placeholder=''
                    size='small'
                    name='countryId'
                    id='countryId'
                    disabled={requestData.requestId !== null}
                    selected={requestData.countryId}
                    getSelected={handleChangeSelect}
                    optionList={staticData.countries}
                  />
                </Grid>

                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Labels align='right' variant='subtitle1' gutterBottom>
                    Request Date
                    <Asterisk>*</Asterisk>
                  </Labels>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <DateUI
                    name='dateRequest'
                    minDate={subYears(new Date(), 20)}
                    maxDate={new Date()}
                    err={errorMessage.dateRequest !== ''}
                    errMsg={errorMessage.dateRequest}
                    defaultValue={requestData.dateRequest}
                    getDate={handleChangeSelect}
                    width='85%'
                  />
                </Grid>
              </Grid>

              <Grid
                container
                item
                columnSpacing={4}
                classes={{ root: classes.gridHeight }}
              >
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Labels align='right' variant='subtitle1' gutterBottom>
                    HCO
                    <Asterisk>*</Asterisk>
                  </Labels>
                </Grid>
                <Grid container item xs={12} sm={4} md={4} lg={4}>
                  <Grid item xs={10} sm={10} md={10} lg={10}>
                    <SelectFilter
                      minWidth='102%'
                      maxWidth='102%'
                      placeholder=''
                      size='small'
                      name='HCO'
                      id='HCO'
                      label='HCO'
                      isEmpty={
                        staticData.HCOs ? staticData.HCOs.length === 1 : false
                      }
                      selected={requestData.HCO}
                      getSelected={handleChangeSelect}
                      optionList={staticData.HCOs}
                    />
                  </Grid>

                  {user.role === 'ADMIN' && (
                    <Grid item xs={2} sm={2} md={2} lg={2}>
                      <Tooltip
                        TransitionComponent={Zoom}
                        arrow
                        placement='top'
                        title='Create New HCO'
                        enterTouchDelay={0}
                      >
                        <Button
                          onClick={handleHCOModal}
                          className='addbutton'
                          size='small'
                          variant='contained'
                          color='success'
                        >
                          <AddIcon fontSize='small' />
                        </Button>
                      </Tooltip>
                    </Grid>
                  )}

                  <Modal
                    open={HCOModalOpen}
                    onClose={(_, reason) => {
                      if (reason !== 'backdropClick') {
                        handleHCOModal();
                      }
                    }}
                  >
                    <div>
                      <Box className='product_modal_box' sx={{ width: '90%' }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <h4 className='modaltitle'>Create New HCO</h4>
                          <Button onClick={handleHCOModal}>
                            <ClearIcon />
                          </Button>
                        </div>
                        <HCOform
                          content={HCOsData}
                          editHCO={HCO}
                          countries={staticData.countries}
                          getHCOData={handleAddHCO}
                          handleClose={handleHCOModal}
                        />
                      </Box>
                    </div>
                  </Modal>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Labels align='right' variant='subtitle1' gutterBottom>
                    GD Committee
                  </Labels>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <DateUI
                    name='dateSeeCommittee'
                    minDate={subYears(new Date(), 20)}
                    maxDate={add(new Date(), { years: 20 })}
                    err={errorMessage.dateSeeCommittee !== ''}
                    errMsg={errorMessage.dateSeeCommittee}
                    defaultValue={requestData.dateSeeCommittee}
                    getDate={handleChangeSelect}
                    width='85%'
                  />
                </Grid>
              </Grid>

              <Grid
                container
                item
                columnSpacing={4}
                classes={{ root: classes.gridHeight }}
              >
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Labels align='right' variant='subtitle1' gutterBottom>
                    Status
                    <Asterisk>*</Asterisk>
                  </Labels>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <SelectFilter
                    minWidth='85%'
                    maxWidth='85%'
                    placeholder=''
                    size='small'
                    name='requestStatusId'
                    id='requestStatusId'
                    error={errorMessage.status !== ''}
                    helperText={errorMessage.status}
                    selected={requestData.requestStatusId}
                    getSelected={handleChangeSelect}
                    optionList={staticData.Status}
                  />
                </Grid>

                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Labels align='right' variant='subtitle1' gutterBottom>
                    Approval Date
                  </Labels>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <DateUI
                    name='dateApproval'
                    minDate={subYears(new Date(), 20)}
                    maxDate={new Date()}
                    defaultValue={requestData.dateApproval}
                    err={errorMessage.dateApproval !== ''}
                    errMsg={errorMessage.dateApproval}
                    getDate={handleChangeSelect}
                    width='85%'
                  />
                </Grid>
              </Grid>

              <Grid
                container
                item
                columnSpacing={4}
                classes={{ root: classes.gridHeight }}
              >
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Labels align='right' variant='subtitle1' gutterBottom>
                    Distributor
                  </Labels>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <SelectFilter
                    minWidth='85%'
                    maxWidth='85%'
                    placeholder=''
                    size='small'
                    name='distributorId'
                    id='distributorId'
                    isEmpty={
                      staticData.distributors
                        ? staticData.distributors.length === 1
                        : false
                    }
                    disabled={reqId === ''}
                    selected={requestData.distributorId}
                    getSelected={handleChangeSelect}
                    optionList={staticData.distributors}
                  />
                </Grid>

                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Labels align='right' variant='subtitle1' gutterBottom>
                    Approval Number
                  </Labels>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <TextField
                    classes={{ root: classes.textField }}
                    placeholder=''
                    size='small'
                    name='approvalNumber'
                    id='approvalNumber'
                    error={errorMessage.approvalNumber !== ''}
                    helperText={errorMessage.approvalNumber}
                    value={requestData.approvalNumber ?? ''}
                    onChange={handleOnChange}
                    variant='outlined'
                  />
                </Grid>
              </Grid>

              <Grid
                container
                item
                columnSpacing={4}
                classes={{ root: classes.gridHeight }}
              >
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Labels align='right' variant='subtitle1' gutterBottom>
                    Securimate code
                    <Asterisk>*</Asterisk>
                  </Labels>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <TextField
                    classes={{ root: classes.textField }}
                    placeholder=''
                    size='small'
                    required
                    name='code'
                    id='code'
                    error={errorMessage.isCodeError}
                    helperText={errorMessage.code}
                    value={requestData.code ?? ''}
                    onChange={handleOnChange}
                    variant='outlined'
                  />
                </Grid>

                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Labels align='right' variant='subtitle1' gutterBottom>
                    Donation Date
                  </Labels>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <DateUI
                    name='dateDonation'
                    minDate={subYears(new Date(), 20)}
                    maxDate={new Date()}
                    err={errorMessage.dateDonation !== ''}
                    errMsg={errorMessage.dateDonation}
                    defaultValue={requestData.dateDonation}
                    getDate={handleChangeSelect}
                    width='85%'
                  />
                </Grid>
              </Grid>

              <Grid container item columnSpacing={4}>
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Labels align='right' variant='subtitle1' gutterBottom>
                    Comments
                  </Labels>
                </Grid>
                <Grid item xs={12} sm={10} md={10} lg={10}>
                  <TextField
                    classes={{ root: classes.textFieldMultiline }}
                    placeholder=''
                    size='small'
                    multiline
                    rows={4}
                    name='comment'
                    id='comment'
                    value={requestData.comment ?? ''}
                    onChange={handleOnChange}
                    variant='outlined'
                  />
                </Grid>
              </Grid>
            </Grid>
            {isRequestIDGenerated ? (
              <>
                <Grid container item xs={12}>
                  <Typography display='inline' variant='h6' gutterBottom>
                    Related Products
                  </Typography>
                </Grid>

                <Grid container item xs={12} lg={12} justifyContent='flex-end'>
                  <Button
                    className='addItemButton'
                    size='small'
                    variant='contained'
                    onClick={handleAddProductModal}
                    startIcon={<AddIcon size='small' />}
                  >
                    Add a Product
                  </Button>
                  <Modal
                    open={addProductModalOpen}
                    onClose={(_, reason) => {
                      if (reason !== 'backdropClick') {
                        handleAddProductModal();
                      }
                    }}
                  >
                    <div>
                      <Box className='product_modal_box responsive-modal'>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <h4 className='modaltitle'>Article Associate</h4>
                          <Button onClick={handleAddProductModal}>
                            <ClearIcon />
                          </Button>
                        </div>
                        <AddDonationProduct
                          addProduct={handleAddDonationProduct}
                          updateProduct={handleUpdateDonationProduct}
                          orgRequestId={requestData.requestId}
                          requestProduct={reqProduct}
                          products={staticData.products}
                          handleClose={handleAddProductModal}
                        />
                      </Box>
                    </div>
                  </Modal>

                  {user.role === 'ADMIN' && (
                    <>
                      <Tooltip
                        TransitionComponent={Zoom}
                        arrow
                        placement='top'
                        title='Create New Product'
                        enterTouchDelay={0}
                      >
                        <Button
                          onClick={handleCreateProductModal}
                          className='addbutton'
                          size='small'
                          variant='contained'
                          color='success'
                        >
                          <AddIcon size='small' />
                        </Button>
                      </Tooltip>
                      <Modal
                        open={createProductModalOpen}
                        onClose={(_, reason) => {
                          if (reason !== 'backdropClick') {
                            handleCreateProductModal();
                          }
                        }}
                      >
                        <div>
                          <Box className='product_modal_box'>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <h4 className='modaltitle'>Create New Product</h4>
                              <Button onClick={handleCreateProductModal}>
                                <ClearIcon />
                              </Button>
                            </div>
                            <Productform
                              editProduct={product}
                              enumCurrency={staticData.enumCurrency}
                              countries={staticData.countries}
                              getProductData={handleAddProduct}
                              handleClose={handleCreateProductModal}
                            />
                          </Box>
                        </div>
                      </Modal>
                    </>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <RegistersTable
                    register={donationProducts}
                    rows={tableRows}
                    handleEdit={handleEditProduct}
                    handleDelete={handleDeleteProduct}
                  />
                </Grid>

                <Grid container item xs={12}>
                  <Typography display='inline' variant='h6' gutterBottom>
                    Related Files
                  </Typography>
                </Grid>

                <Grid container sx={{ m: '2% 0', h: '3rem' }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={12}
                    sx={{ pt: '0' }}
                    alignSelf='center'
                  >
                    {requestData.file &&
                      requestData.file.map((f, i) => (
                        <Chip
                          key={i}
                          sx={{ ml: '12px', mt: '12px' }}
                          label={f.file_name}
                          disabled={clickedChipIndex === i}
                          clickable
                          onClick={() => handleDownloadFile(f, i)}
                          onDelete={() => {
                            setDeleteFile(f);
                            setClickedChipIndex(i);
                            setDoDelete(true);
                          }}
                          deleteIcon={<DeleteIcon />}
                        />
                      ))}
                    {doDelete && (
                      <div>
                        <Dialog
                          sx={{
                            '& .MuiDialog-paper': {
                              width: '80%',
                              maxHeight: 435,
                            },
                          }}
                          maxWidth='xs'
                          open={doDelete}
                          onClose={() => {
                            setClickedChipIndex(null);
                            setDoDelete(false);
                          }}
                        >
                          <DialogTitle>{`Delete File`}</DialogTitle>
                          <DialogContent dividers>
                            Are you sure you want to delete this file?
                          </DialogContent>
                          <DialogActions>
                            <Button
                              variant='outlined'
                              autoFocus
                              onClick={() => {
                                setClickedChipIndex(null);
                                setDoDelete(false);
                              }}
                            >
                              No
                            </Button>
                            <Button
                              variant='contained'
                              onClick={handleDeleteFile}
                            >
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      lg={12}
                      textAlign='right'
                      sx={{ height: '60px' }}
                    >
                      <InputLabel htmlFor='upload' className='upload-btn'>
                        {' '}
                        Choose File
                        <Input
                          id='upload'
                          sx={{ display: 'none' }}
                          onInput={handleFileSelect}
                          onClick={(e) => {
                            e.target.value = null;
                            setSizeError('');
                            setSelFiles(null);
                          }}
                          type='file'
                          disableUnderline
                          disabled={isUploading}
                        />
                      </InputLabel>
                      <Tooltip
                        TransitionComponent={Zoom}
                        arrow
                        placement='top'
                        title='Choose file(s) to enable'
                        enterTouchDelay={0}
                        open={
                          (selFiles === null || fileSizeError !== '') &&
                          showTooltip
                        }
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                      >
                        <span>
                          <LoadingButton
                            className='form-btn'
                            size='small'
                            variant='contained'
                            component='span'
                            onClick={handleUploadFile}
                            disabled={selFiles === null || fileSizeError !== ''}
                            startIcon={<AttachFileIcon size='small' />}
                            loadingIndicator='Uploading...'
                            loading={isUploading}
                          >
                            Add a File
                          </LoadingButton>
                        </span>
                      </Tooltip>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      textAlign='right'
                      sx={{ height: '20px' }}
                    >
                      <Typography
                        display='inline'
                        variant='body2'
                        gutterBottom
                        sx={{ color: `${selFiles ? 'black' : '#d32f2f'}` }}
                      >
                        {selFiles?.name || fileSizeError}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              ''
            )}
            <Grid item xs={12} sx={{ height: '50px' }}>
              {alarmErr && (
                <Alt sx={{ whiteSpace: 'pre-line' }} severity='error'>
                  {alarmErr}
                </Alt>
              )}
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} textAlign='right'>
                <SubmitButton />
                <Button
                  size='small'
                  variant='contained'
                  color='error'
                  className='form-btn'
                  startIcon={<ListIcon size='small' />}
                  onClick={handleReturnClick}
                >
                  RETURN TO DONATION REQUEST LIST
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
}

export default CreateDonationRequest;
