import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { subYears, add } from 'date-fns';
import { Button, Box, Grid, TextField, Tooltip, Zoom } from '@mui/material';
import SelectFilter from '../Components/UI/SelectFilter';
import DateUI from '../Components/UI/DateUI';
import { Asterisk, Labels, checkFormat } from '../Components/UI/Common';
import '../Styles/Products.css';

const useStyles = makeStyles(() => ({
  textField: {
    width: '100%',
  },
  gridHeight: {
    height: '80px',
  },
  width: {
    width: '90%',
  },
}));

const defaultError = {
  requestQuantity: '',
  approvedQuantity: '',
  donatedQuantity: '',
  lotSerialNo: '',
};

const defaultRequestProduct = {
  productId: 0,
  quantity: 1,
  quantityApproved: 0,
  quantityDonated: 0,
  expDate: '',
  lotSerialNo: '',
};

function AddDonationProduct(props) {
  const classes = useStyles();
  const {
    products,
    orgRequestId,
    requestProduct,
    updateProduct,
    addProduct,
    handleClose,
  } = props;
  const [requestProductData, setRequestProductData] = useState(
    defaultRequestProduct
  );
  const [errorMessage, setErrorMessage] = useState(defaultError);
  const [isSave, setIsSave] = useState(true);
  const [isCheck, setIsCheck] = useState(false);

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name } = e.target;
    const { value } = e.target;
    setRequestProductData((prevState) => {
      const field = prevState;
      field[name] = value;
      return {
        ...field,
      };
    });
    setIsCheck(true);
  };

  useEffect(() => {
    if (isCheck) {
      if(!checkFormat(requestProductData.quantity, 4)) {
        setIsSave(false);
        setErrorMessage({
          ...defaultError,
          requestQuantity: 'Must be positive integer greater than zero',
        });
      } else if(!checkFormat(requestProductData.quantityApproved, 5)) {
        setIsSave(false);
        setErrorMessage({
          ...defaultError,
          approvedQuantity: 'Must be positive integer',
        });
      } else if(!checkFormat(requestProductData.quantityDonated, 5)) {
        setIsSave(false);
        setErrorMessage({
          ...defaultError,
          donatedQuantity: 'Must be positive integer',
        });
      } else if (+requestProductData.quantityApproved > +requestProductData.quantity) {
        setIsSave(false);
        setErrorMessage({
          ...defaultError,
          approvedQuantity: 'Approved quantity is more than Requested quantity',
        });
      } else if (
        +requestProductData.quantityDonated >
        +requestProductData.quantityApproved
      ) {
        setIsSave(false);
        setErrorMessage({
          ...defaultError,
          donatedQuantity: 'Donated quantity is more than Approved quantity',
        });
      } else if (
        requestProductData.lotSerialNo !== '' &&
        checkFormat(requestProductData.lotSerialNo, 3)
      ) {
        setIsSave(false);
        setErrorMessage({
          ...defaultError,
          lotSerialNo: 'Must not include special characters, hyphen(-) allowed',
        });
      } else {
        setErrorMessage({
          ...defaultError,
        });
        setIsSave(true);
      }
      setIsCheck(false);
    }
  }, [isCheck]);

  const handleChangeSelect = (value, name) => {
    setRequestProductData((prevState) => {
      const field = prevState;
      field[name] = value;
      return {
        ...field,
      };
    });
  };

  const handleClickSave = () => {
    const addDtnPrdData = {
      productId: requestProductData.productId,
      quantity: parseInt(requestProductData.quantity, 10),
      quantityApproved: parseInt(requestProductData.quantityApproved, 10),
      quantityDonated: parseInt(requestProductData.quantityDonated, 10),
      expDate: requestProductData.expDate,
      lotSerialNo: requestProductData.lotSerialNo,
      orgRequestId: orgRequestId,
      orgRequestProductId: requestProduct ? requestProduct.id : 0,
    };
    if (requestProduct.productId !== -1) {
      updateProduct(addDtnPrdData);
    } else {
      addProduct(addDtnPrdData);
    }
    handleClose();
  };

  useEffect(() => {
    setRequestProductData({ ...requestProduct });
  }, []);

  function SubmitButton() {
    if (!(isSave && requestProductData.productId !== -1)) {
      return (
        <Tooltip
          TransitionComponent={Zoom}
          arrow
          placement='top'
          title='Fill mandatory fields to enable'
          enterTouchDelay={0}
        >
          <span>
            <Button variant='contained' disabled>
              Save Changes
            </Button>
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Button onClick={handleClickSave} variant='contained'>
          Save Changes
        </Button>
      );
    }
  }

  return (
    <div>
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <Grid container spacing={2} classes={{ root: classes.gridHeight }}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Labels align='right' variant='subtitle1'>
              Product
              <Asterisk>*</Asterisk>
            </Labels>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={6}>
            <SelectFilter
              minWidth='100%'
              placeholder=''
              size='small'
              name='productId'
              id='productId'
              isEmpty={products ? products.length === 1 : false}
              disabled={requestProduct.productId !== -1}
              getSelected={handleChangeSelect}
              selected={requestProductData.productId}
              optionList={products}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} classes={{ root: classes.gridHeight }}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Labels align='right' variant='subtitle1'>
              Requested Quantitiy
            </Labels>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={6}>
            <TextField
              classes={{ root: classes.textField }}
              placeholder=''
              size='small'
              required
              name='quantity'
              id='quantity'
              error={errorMessage.requestQuantity !== ''}
              helperText={errorMessage.requestQuantity}
              onChange={handleOnChange}
              value={requestProductData.quantity}
              variant='outlined'
              type='number'
              inputProps={{ min: 0 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} classes={{ root: classes.gridHeight }}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Labels align='right' variant='subtitle1'>
              Approved Quantitiy
            </Labels>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={6}>
            <TextField
              classes={{ root: classes.textField }}
              placeholder=''
              size='small'
              required
              error={errorMessage.approvedQuantity !== ''}
              helperText={errorMessage.approvedQuantity}
              name='quantityApproved'
              id='quantityApproved'
              onChange={handleOnChange}
              value={requestProductData.quantityApproved}
              variant='outlined'
              type='number'
              inputProps={{ min: 0 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} classes={{ root: classes.gridHeight }}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Labels align='right' variant='subtitle1'>
              Donated Quantitiy
            </Labels>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={6}>
            <TextField
              classes={{ root: classes.textField }}
              placeholder=''
              size='small'
              required
              name='quantityDonated'
              id='quantityDonated'
              error={errorMessage.donatedQuantity !== ''}
              helperText={errorMessage.donatedQuantity}
              onChange={handleOnChange}
              value={requestProductData.quantityDonated}
              variant='outlined'
              type='number'
              inputProps={{ min: 0 }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} classes={{ root: classes.gridHeight }}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Labels align='right' variant='subtitle1'>
              Lot Serial Number
            </Labels>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={6}>
            <TextField
              classes={{ root: classes.textField }}
              placeholder=''
              size='small'
              required
              name='lotSerialNo'
              id='lotSerialNo'
              error={errorMessage.lotSerialNo !== ''}
              helperText={errorMessage.lotSerialNo}
              onChange={handleOnChange}
              value={requestProductData.lotSerialNo}
              variant='outlined'
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} classes={{ root: classes.gridHeight }}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Labels align='right' variant='subtitle1'>
              Expiration Date
            </Labels>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={6}>
            <DateUI
              minDate={subYears(new Date(), 20)}
              maxDate={add(new Date(), { years: 20 })}
              name='expDate'
              defaultValue={requestProductData.expDate}
              getDate={handleChangeSelect}
              width='100%'
            />
          </Grid>
        </Grid>
      </Box>

      <div className='buttondiv_productform'>
        <Button onClick={handleClose} variant='outlined'>
          Cancel
        </Button>
        <SubmitButton />
      </div>
    </div>
  );
}

AddDonationProduct.propTypes = {
  products: PropTypes.instanceOf(Object).isRequired,
  orgRequestId: PropTypes.number,
  requestProduct: PropTypes.instanceOf(Object).isRequired,
  addProduct: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

AddDonationProduct.defaultProps = {
  orgRequestId: null,
};

export default AddDonationProduct;
