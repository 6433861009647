import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import {
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Zoom,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  IconButton,
  styled,
  Skeleton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import PrintIcon from '@mui/icons-material/PrintRounded';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const useStyles = makeStyles({
  iconCell: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  tableCell: {
    whiteSpace: 'break-spaces',
    width: '190px',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
});

const Cell = styled(TableCell)({
  textAlign: 'center',
})

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

// This component displays table of data with edit and delete options.
function RegistersTable(props) {
  const classes = useStyles();
  const { register, filter, searching, isDelete, rows, deleting } = props;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [id, setId] = useState(0);
  const skeletonArray = Array(3).fill('');

  const handleCancel = () => {
    setOpen(false);
  };

  const handleDelete = (entity) => {
    let tableName = entity.data[0];
    if (register.tableName?.toLowerCase() === 'request') {
      tableName = tableName.slice(0, 12);
    }
    setValue(tableName);
    setId(entity.Id);
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
    props.handleDelete(id);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div>
      <TableContainer>
        <Table
          sx={{ minWidth: '100%' }}
          size='small'
          aria-label='register table'
        >
          <TableHead>
            <TableRow sx={{ borderTop: 1 }}>
              {register.tablehead.map((head, i) => (
                <Cell
                  key={`td-${i}`}
                  sx={{ fontWeight: 'bold' }}
                  classes={{ root: classes.tableCell }}
                >
                  {head}
                </Cell>
              ))}
            </TableRow>
          </TableHead>

          {searching ? (
            skeletonArray.map((item, i) => (
              <TableBody key={`tb-${i}`}>
                <TableRow key={`tr-${i}`}>
                  {register.tablehead.map((head, j) => (
                    <Cell key={`td-${j}`} classes={{ root: classes.tableCell }}>
                      <Skeleton variant='rectangular' height={10} />
                    </Cell>
                  ))}
                </TableRow>
              </TableBody>
            ))
          ) : rows.length > 0 ? (
            <>
              <TableBody>
                {(rowsPerPage > 0
                  ? rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                ).map((row) => (
                  <TableRow key={row.Id}>
                    {row.data.map((value, i) => (
                      <Cell key={`td-${i}`} classes={{ root: classes.tableCell }}>
                        {value}
                      </Cell>
                    ))}
                    {register.tableName?.toLowerCase() === 'request' && (
                      <Cell sx={{ minWidth: 120 }}>
                        <Tooltip
                          TransitionComponent={Zoom}
                          arrow
                          placement='top'
                          title='Print Donation History'
                          onClick={() => props.handleDonationHistory(row.Id)}
                          value={row.Id}
                          enterTouchDelay={0}
                        >
                          <IconButton aria-label='print'>
                            <PrintIcon color='success' />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          TransitionComponent={Zoom}
                          arrow
                          placement='top'
                          title={
                            filter.countryId === 4
                              ? 'Not Available'
                              : 'Print Donation Letter'
                          }
                          enterTouchDelay={0}
                        >
                          <span>
                            <IconButton
                              aria-label='print'
                              onClick={() => props.handleDonationLetter(row.Id)}
                              value={row.Id}
                              disabled={filter.countryId === 4}
                            >
                              <PrintIcon color='warning' />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip
                          TransitionComponent={Zoom}
                          arrow
                          placement='top'
                          title={
                            filter.countryId === 4
                              ? 'Not Available'
                              : 'Print Appendix'
                          }
                          enterTouchDelay={0}
                        >
                          <span>
                            <IconButton
                              aria-label='print'
                              onClick={() =>
                                props.handleDonationAppendix(row.Id)
                              }
                              value={row.Id}
                              disabled={filter.countryId === 4}
                            >
                              <PrintIcon color='primary' />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Cell>
                    )}

                    <Cell className={classes.iconCell}>
                      <Tooltip
                        TransitionComponent={Zoom}
                        arrow
                        placement='top'
                        title='Edit'
                        enterTouchDelay={0}
                      >
                        <IconButton
                          onClick={() => {
                            props.handleEdit(row.Id);
                          }}
                          value={row.Id}
                          aria-label='edit'
                          disabled={deleting}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>

                      {!isDelete && (
                        <Tooltip
                          TransitionComponent={Zoom}
                          arrow
                          placement='top'
                          title='Delete'
                          enterTouchDelay={0}
                        >
                          <IconButton
                            onClick={() => {
                              handleDelete(row);
                            }}
                            value={row.Id}
                            aria-label='delete'
                            disabled={deleting}
                          >
                            <DeleteIcon color='error' />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Cell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <Cell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: 'All', value: -1 },
                    ]}
                    colSpan={7}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </>
          ) : (
            <TableBody>
              <TableRow>
                <Cell
                  colSpan={register.tablehead.length}
                >
                  No results found.
                </Cell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {/* Dialog box when deleting an item */}
      {value && (
        <div>
          <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth='xs'
            open={open}
            onClose={handleCancel}
          >
            <DialogTitle>
              {`Delete ${register.tableName}`}
            </DialogTitle>
            <DialogContent dividers>
              Are you sure you want to delete the {register.tableName} '{value}
              '?
            </DialogContent>
            <DialogActions>
              <Button variant='outlined' autoFocus onClick={handleCancel}>
                Cancel
              </Button>
              <Button variant='contained' onClick={handleOk}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
}

export default RegistersTable;
