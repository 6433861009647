import { useState, useEffect } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { subYears, add } from 'date-fns';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import SelectFilter from './UI/SelectFilter';
import DateUI from './UI/DateUI';
import '../Styles/Products.css';

// This component is used to search Donation requests
function RequestFilter(props) {
  const [country, setCountry] = useState(props.countries[0].id);
  const [searchTerm, setsearchTerm] = useState('');
  const [periodRepresents, setperiodRepresents] = useState(
    props.requestPeriod[0].id
  );
  const [start, setStart] = useState(subYears(new Date(), 2));
  const [end, setEnd] = useState(new Date());
  const [HCO, setHCO] = useState(props.HCOs[0].id);
  const [status, setStatus] = useState(props.requestStatus[0].id);
  const [HCOs, setHCOs] = useState(props.HCOs);
  const [isEmpty, setIsEmpty] = useState(false);

  const handleChangeCountry = (value) => {
    setCountry(value);
  };

  const handleChangeSearchTerm = (event) => {
    setsearchTerm(event.target.value);
  };

  const handleChangePeriod = (value) => {
    setperiodRepresents(value);
  };

  const handleChangeStart = (value) => {
    setStart(value);
  };

  const handleChangeEnd = (value) => {
    setEnd(value);
  };

  const handleChangeHCO = (value) => {
    setHCO(value);
  };

  const handleChangeStatus = (value) => {
    setStatus(value);
  };

  const handleClick = (e) => {
    if (e.target.id === 'search') {
      props.fwdState.set(true);
      props.getData({
        countryId: country,
        organizationId: HCO,
        search: searchTerm,
        periodStart: start,
        periodEnd: end,
        requestPeriodId: periodRepresents,
        requestStatusId: status,
      });
    } else if (e.target.id === 'clear') {
      const {
        defaultFilterVal: {
          countryId,
          search,
          requestPeriodId,
          periodStart,
          periodEnd,
          organizationId,
          requestStatusId,
        },
      } = props;
      setCountry(countryId);
      setsearchTerm(search);
      setperiodRepresents(requestPeriodId);
      setStart(periodStart);
      setEnd(periodEnd);
      setHCO(organizationId);
      setStatus(requestStatusId);
    }
  };

  //Filter HCOs by country
  useEffect(() => {
    const filterHCO = props.HCOs.filter((h) => h.countryId === country || h.id === 0);

    if (filterHCO.length === 1 && filterHCO[0].id === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
    setHCOs(filterHCO);
  }, [country, setHCOs]);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <label className='form-label' htmlFor='country'>
            Country
          </label>
          <SelectFilter
            name='country'
            selected={country}
            getSelected={handleChangeCountry}
            minWidth='100%'
            optionList={props.countries}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <label className='form-label' htmlFor='searchTerm'>
            Search Term
          </label>
          <TextField
            sx={{ width: '100%' }}
            id='searchTerm'
            value={searchTerm}
            type='text'
            placeholder='HCO, product, code or request ID'
            onChange={handleChangeSearchTerm}
            size='small'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <label className='form-label' htmlFor='request'>
            Period Represents
          </label>
          <SelectFilter
            name='request'
            selected={periodRepresents}
            getSelected={handleChangePeriod}
            minWidth='100%'
            optionList={props.requestPeriod}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <label className='form-label' htmlFor='periodStarts'>
            Period Starts
          </label>
          <DateUI
            id='periodStarts'
            clearAllowed={false}
            minDate={subYears(new Date(), 20)}
            maxDate={add(new Date(), { years: 20 })}
            getDate={handleChangeStart}
            defaultValue={start}
            width='100%'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <label className='form-label' htmlFor='periodEnds'>
            Period Ends
          </label>
          <DateUI
            id='periodEnds'
            clearAllowed={false}
            minDate={subYears(new Date(), 20)}
            maxDate={add(new Date(), { years: 20 })}
            getDate={handleChangeEnd}
            width='100%'
            defaultValue={end}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <label className='form-label' htmlFor='hco'>
            HCOs
          </label>
          <SelectFilter
            name='hco'
            selected={HCO}
            getSelected={handleChangeHCO}
            minWidth='100%'
            isEmpty={isEmpty}
            optionList={HCOs}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <label className='form-label' htmlFor='status'>
            Request Status
          </label>
          <SelectFilter
            name='status'
            selected={status}
            getSelected={handleChangeStatus}
            minWidth='100%'
            optionList={props.requestStatus}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Button
            id='search'
            variant='contained'
            className='search-btn'
            onClick={handleClick}
            disabled={isEmpty}
            startIcon={<SearchIcon />}
          >
            Search
          </Button>
          <Button
            id='clear'
            variant='contained'
            className='search-btn'
            onClick={handleClick}
            startIcon={<ClearIcon />}
          >
            Clear Search
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default RequestFilter;
