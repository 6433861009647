import {
  Route, Redirect,
} from 'react-router-dom';
import MenuAppBar from './Components/MenuAppBar';
import { useAuthContext } from './Components/Auth/authContext';
import BackdropLoader from './Components/UI/BackdropLoader';

const priviledgedRoutes = ['/registers/products', '/registers/HCOs', '/settings/Alarm',
  '/settings/CurrencyList', '/settings/AccountMgmt'];
const commonRoutes = ['/auth/callback/', '/Dashboard', '/createdonation', '/registers/Distributors'];

function PrivateRoute({ component: Component, ...rest }) {
  const { user, wait } = useAuthContext();

  if (wait) {
    return (
      <BackdropLoader show={!wait} />
    );
  }

  function checkRoute(props, params) {
    const isCommonRoute = commonRoutes.some((route) => params.path.toUpperCase() === route.toUpperCase());
    const isPriviledgedRoute = priviledgedRoutes.some((route) => params.path.toUpperCase() === route.toUpperCase());
    if (user.user_role !== undefined) {
      if (isCommonRoute) {
        return <Component {...props} {...params} />;
      } if (isPriviledgedRoute) {
        if (user.user_role === 1) {
          return <Component {...props} {...params} />;
        }
      }
    }
    return <Redirect to="/accessDenied" />;
  }

  return (
    <div className="container">
      <Route
        {...rest}
        render={(props) => (
          <>
            <MenuAppBar />
            {checkRoute(props, rest)}
          </>
        )}
      />
    </div>
  );
}

export default PrivateRoute;
