import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField, IconButton } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ClearIcon from '@mui/icons-material/Clear';
import { format } from 'date-fns';
import { utils } from '../../Data';

const useStyles = makeStyles({
  outlinedInput: {
    height: '2.175rem',
  },
});

function DateUI(props) {
  const classes = useStyles();
  const {
    defaultValue,
    minDate,
    maxDate,
    name,
    labelName,
    err,
    errMsg,
    clearAllowed,
    getDate,
    width,
  } = props;
  const [value, setValue] = useState(null);
  const dateName = name || '';

  const handleChange = (changedValue) => {
    setValue(changedValue);
    changedValue = format(changedValue, utils.dateFormat);
    getDate(changedValue, dateName);
  };

  const handleClear = () => {
    setValue(null);
    getDate(null, dateName);
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  function endAdornment() {
    return clearAllowed || clearAllowed === undefined ? (
      <IconButton onClick={handleClear}>
        <ClearIcon />
      </IconButton>
    ) : null;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={labelName}
        value={value}
        mask='__-__-____'
        inputFormat='dd-MM-yyyy'
        onChange={handleChange}
        maxDate={maxDate}
        minDate={minDate}
        inputProps={{ placeholder: 'Select Date - DD/MM/YYYY', readOnly: true }}
        InputProps={{
          className: classes.outlinedInput,
          endAdornment: endAdornment(),
        }}
        InputAdornmentProps={{
          position: 'start',
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size='small'
            error={err}
            helperText={errMsg}
            sx={{ width: width ?? '150px' }}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default DateUI;
