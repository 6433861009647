import {
  Box,
  Button,
  Grid,
  Modal,
  Snackbar,
  TextField,
  Tooltip,
  Zoom,
  useMediaQuery,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SelectFilter from '../Components/UI/SelectFilter';
import BackdropLoader from '../Components/UI/BackdropLoader';
import RegistersTable from '../Components/UI/RegistersTable';
import { Alert } from '../Components/UI/Common';
import { products, utils } from '../Data';
import { csvToArr, apiGatwayCall } from '../Components/Utils/utils';
import getEnum from '../Components/Utils/Enum';
import { useAuthContext } from '../Components/Auth/authContext';
import '../Styles/Products.css';
import Productform from './Productform';

const Input = styled('input')({
  display: 'none',
});

const defaultProduct = {
  productId: '',
  countryId: 1,
  enumCurrencyId: 166,
  code: '',
  name: '',
  brand: '',
  price: '',
  dosage: '',
  typeOfApplication: '',
  localTypeOfApplication: '',
  description: '',
};

export default function Products() {
  const match = useMediaQuery('(max-width: 1100px)');
  const [country, setCountry] = useState(defaultProduct.countryId);
  const [search, setSearch] = useState('');
  const [tableRows, setTableRows] = useState([]);
  const [isUpdate, setIsUpdate] = useState(true);
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ message: '', type: '' });
  const [alertOpen, setAlertOpen] = useState(false);
  const [enumCurrency, setEnumCurrency] = useState({});
  const [product, setProduct] = useState(defaultProduct);
  const [isLoading, setIsLoading] = useState(true);
  const [allCountries, setAllCountries] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isEditSearch, setIsEditSearch] = useState(false);
  const { user } = useAuthContext();

  const handleAlertClick = (message, type) => {
    setAlert({
      message,
      type,
    });
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  // Upload file to backend
  const handleImportFile = async (e) => {
    let prdArray = [];
    const countries = allCountries;
    const reader = new FileReader();
    reader.onload = async function (event) {
      const data = event.target.result;
      prdArray = csvToArr(data);
      let errorMessage = '';
      prdArray.forEach((row, i) => {
        if (i === 0) return;
        if (row.length === 3) {
          const icountry = countries.find((c) => {
            if (c.code === row[0]) {
              return c.id;
            }
          });
          if (icountry) {
            row[3] = icountry.id;
          } else {
            errorMessage += `Line ${i + 1} has unknown country code,\n`;
          }
        } else {
          errorMessage += `Line ${i + 1} has invalid number of columns,\r\n`;
        }
      });
      if (errorMessage === '') {
        prdArray.shift();
        const importPrdData = {
          fileData: prdArray,
          createdBy: user.user_id,
          updatedAt: format(new Date(), utils.dateFormat),
          updatedBy: user.user_id,
        };

        await apiGatwayCall(user.token, importPrdData, 'importproducts')
          .then((response) => {
            const { message, statusCode } = response.data;
            if (statusCode === 200) {
              setIsUpdate(true);
              handleAlertClick(message, 'success');
            } else if (statusCode === 503) {
              setIsUpdate(true);
              handleAlertClick(message, 'error');
            }
          })
          .catch(() => handleAlertClick(utils.errorMessage, 'error'));
      } else {
        handleAlertClick(`Import Failed, ${errorMessage}`, 'error');
      }
    };

    if (e.target.files[0]) {
      const fileName = e.target.files[0].name;
      const isValid = checkValidFileType(fileName);
      if (isValid) {
        reader.readAsArrayBuffer(e.target.files[0]);
        e.target.value = '';
      } else {
        handleAlertClick(`Invalid file type! File type must either be CSV or Excel`, 'error');
      }
    }
  };

  const checkValidFileType = (name) => {
    const lastDotIndex = name.lastIndexOf('.');
    const ext = name.substring(lastDotIndex + 1);
    const allowedType = ['csv', 'xls', 'xlsx', 'number'];
    const isValid = allowedType.includes(ext);
    return isValid;
  };

  const handleChangeCountry = (value) => {
    setCountry(value);
    setSearch('');
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };
  const handleModalOpen = () => {
    setOpen(true);
    setProduct(defaultProduct);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleClickSearch = (e) => {
    if (e.target.id === 'search') {
      setIsSearching(true);
      setIsUpdate(true);
    } else if (e.target.id === 'clear') {
      setCountry(defaultProduct.countryId);
      setSearch('');
      setIsUpdate(false);
    }
  };

  // POST request to add Product to database
  const handleAddProduct = (aProduct) => {
    const addPrdData = {
      ...aProduct,
      createdBy: user.user_id,
      updatedBy: user.user_id,
      createdAt: format(new Date(), utils.dateFormat),
    };

    async function add() {
      await apiGatwayCall(user.token, addPrdData, 'addproduct')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setOpen(false);
            setIsUpdate(true);
            handleAlertClick('Product added successfully.', 'success');
          } else if (statusCode === 503) {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    add();
  };

  // PUT request to update a Product data
  const handleUpdateProduct = (uProduct) => {
    const updPrdData = {
      ...uProduct,
      id: uProduct.productId,
      updatedAt: format(new Date(), utils.dateFormat),
      updatedBy: user.user_id,
    };
    async function putData() {
      await apiGatwayCall(user.token, updPrdData, 'updateproduct')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setIsUpdate(true);
            handleAlertClick('Product updated successfully.', 'success');
          } else if (statusCode === 503) {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    putData();
    setOpen(false);
  };

  // GET request to fetch a Product data
  const handleEditProduct = (pID) => {
    setIsEditSearch(true);
    async function getData() {
      await apiGatwayCall(user.token, { id: pID }, 'getproduct')
        .then((response) => {
          const { data, message, statusCode } = response.data;
          if (statusCode === 200) {
            setProduct({
              productId: data[0].product_id,
              countryId: data[0].country_id,
              enumCurrencyId: data[0].enum_currency_id,
              code: data[0].code,
              name: data[0].name,
              brand: data[0].brand,
              price: data[0].price,
              dosage: data[0].dosage,
              typeOfApplication: data[0].type_of_application,
              localTypeOfApplication: data[0].local_type_of_application,
              description: data[0].description,
            });
            setIsEditSearch(false);
            setOpen(true);
          } else {
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    getData();
    setIsUpdate(true);
  };

  // POST request to delete a Product
  const handleDeleteProduct = (delPrdId) => {
    const delPrdData = {
      id: delPrdId,
      updatedBy: user.user_id,
      updatedAt: format(new Date(), utils.dateFormat),
    };
    async function deleteData() {
      await apiGatwayCall(user.token, delPrdData, 'deleteproduct')
        .then((response) => {
          const { message, statusCode } = response.data;
          if (statusCode === 200) {
            setIsUpdate(true);
            handleAlertClick('Product removed successfully.', 'success');
          } else {
            setIsUpdate(true);
            handleAlertClick(message, 'error');
          }
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    deleteData();
  };

  // GET request to fetch all Products from database
  useEffect(() => {
    const filterData = { countryid: country, search };
    async function getProducts() {
      await apiGatwayCall(user.token, filterData, 'getproductList')
        .then((response) => {
          const { data, message, statusCode } = response.data;
          if (statusCode === 200) {
            const rows = data.map((p) => ({
              Id: p.product_id,
              data: [p.name, p.pcode, p.price, p.ecode, p.description],
            }));
            setTableRows(rows);
          } else if (statusCode === 404) {
            setTableRows([]);
          } else {
            handleAlertClick(message, 'error');
          }
          setIsSearching(false);
        })
        .catch(() => handleAlertClick(utils.errorMessage, 'error'));
    }
    if (isUpdate) {
      getProducts();
      setIsUpdate(false);
    }
  }, [isUpdate, country]);

  // GET request to fetch Currency, Country data
  useEffect(() => {
    async function getEnumCurrency() {
      const enumData = await getEnum(user.token, ['enum_currency']);

      const eCurrency = enumData.enum_currency.map((currency) => ({
        id: currency.enum_currency_id,
        name: currency.code,
      }));
      setEnumCurrency(eCurrency);
    }

    async function getCountries() {
      const countryData = await getEnum(user.token, ['country']);
      const ctr = countryData.country.map((e) => ({
        id: e.country_id,
        name: e.name,
        code: e.code,
      }));
      setAllCountries(ctr);
      setIsLoading(false);
    }
    getEnumCurrency();
    getCountries();
  }, []);

  function ImportButton() {
    return match ? (
      <Tooltip
        TransitionComponent={Zoom}
        arrow
        placement='top'
        title='Import Products'
        enterTouchDelay={0}
      >
        <Button
          className='addbutton'
          size='small'
          variant='contained'
          component='span'
          color='info'
        >
          <UploadFileIcon fontSize='small' />
        </Button>
      </Tooltip>
    ) : (
      <Button
        className='form-btn'
        size='medium'
        variant='contained'
        component='span'
        startIcon={<UploadFileIcon size='small' />}
      >
        Import Products
      </Button>
    );
  }

  return (
    <div>
      {isLoading ? (
        <BackdropLoader show={isLoading} />
      ) : (
        <div>
          <BackdropLoader show={isEditSearch} />
          <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleAlertClose}
          >
            <Alert onClose={handleAlertClose} severity={alert.type}>
              {alert.message}
            </Alert>
          </Snackbar>
          <Grid container item xs={12}>
            <h2 className='product-title'>Product List</h2>
          </Grid>

          <Grid container direction='row' spacing={1}>
            <Grid item xs={12} sm={3} md={2} lg={2}>
              <label className='form-label' htmlFor='country'>
                Country
              </label>
              <SelectFilter
                name='country'
                id='country'
                selected={country}
                getSelected={handleChangeCountry}
                minWidth='100%'
                optionList={allCountries}
              />
            </Grid>

            <Grid item xs={12} sm={9} md={4} lg={3}>
              <label className='form-label' htmlFor='search'>
                Search Term
              </label>
              <TextField
                sx={{ width: '100%' }}
                id='search'
                name='search'
                type='text'
                placeholder='Search by product name or code'
                size='small'
                value={search}
                onChange={handleChangeSearch}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={5} lg={3}>
              <Button
                id='search'
                variant='contained'
                className='search-btn'
                startIcon={<SearchIcon />}
                onClick={handleClickSearch}
              >
                Search
              </Button>
              <Button
                id='clear'
                variant='contained'
                className='search-btn'
                startIcon={<ClearIcon />}
                onClick={handleClickSearch}
              >
                Clear Search
              </Button>
            </Grid>

            <Grid
              xs={12}
              sm={6}
              md={12}
              lg={4}
              item
              container
              alignItems='center'
              justifyContent='flex-end'
            >
              <Button
                onClick={handleModalOpen}
                className='form-btn'
                size='medium'
                color='success'
                variant='contained'
                startIcon={<AddIcon size='small' />}
              >
                Add New Product
              </Button>
              <label htmlFor='contained-button-file'>
                <Input
                  id='contained-button-file'
                  onChange={handleImportFile}
                  multiple
                  type='file'
                  accept='.xlsx, .xls, .csv, .numbers'
                />
                <ImportButton />
              </label>
            </Grid>
          </Grid>
          <Modal
            open={open}
            onClose={(_, reason) => {
              if (reason !== 'backdropClick') {
                handleModalClose();
              }
            }}
            aria-labelledby='parent-modal-title'
            aria-describedby='parent-modal-description'
          >
            <div>
              <Box className='product_modal_box'>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <h4 className='modaltitle'>
                    {product.productId === ''
                      ? 'Create New Product'
                      : 'Edit Product'}
                  </h4>
                  <Button onClick={handleModalClose}>
                    <ClearIcon />
                  </Button>
                </div>
                <Productform
                  editProduct={product}
                  countries={allCountries}
                  updateProductData={handleUpdateProduct}
                  getProductData={handleAddProduct}
                  enumCurrency={enumCurrency}
                  handleClose={handleModalClose}
                />
              </Box>
            </div>
          </Modal>

          <RegistersTable
            searching={isSearching}
            register={products}
            rows={tableRows}
            handleEdit={handleEditProduct}
            handleDelete={handleDeleteProduct}
          />
        </div>
      )}
    </div>
  );
}
