import { forwardRef } from 'react';
import {
  Alert as MuiAlert,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';


export const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={10} ref={ref} variant='filled' sx={{ width: '100%' }} {...props} />
));

export const Asterisk = styled('label')({
  color: 'red',
});

export const Labels = styled(Typography)({
  fontWeight: '600',
  float: 'right',
});

export function checkFormat(val, format) {
  let regEx = '';
  switch (format) { 
    case 1: 
      regEx = /^(?=.*[1-9])(?:[1-9]\d*\.?|0?\.)\d*$/gm;
      break;
    case 2: 
      regEx = /^(?=.*[0-9])(?:[0-9]\d*\.?|0?\.)\d*$/gm;
      break;
    case 3:
      regEx = /[*|!#%^\\/_+="?.,~\":<>[\]{}`\\()';@&$]/gm;
      break;
    case 4:
      regEx= /^0*[1-9][0-9]*$/gm;
      break;
    case 5:
      regEx= /^\d+$/gm;
      break;
  }
  const isMatch = regEx.test(val);
  return isMatch;
}

export function CustomDialog(props) {
  const {
    content: { title, message, leftBtn, rightBtn },
    open,
    handleCancel,
    handleOk,
  } = props;

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth='xs'
      open={open}
      onClose={handleCancel}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{message}</DialogContent>
      <DialogActions>
        <Button variant='outlined' autoFocus onClick={handleCancel}>
          {leftBtn}
        </Button>
        <Button variant='contained' onClick={handleOk}>
          {rightBtn}
        </Button>
      </DialogActions>
    </Dialog>
  );
}