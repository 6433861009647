import { AuthProvider } from '@elilillyco/spa_auth';
import config from '../Auth/config.json';

const authConfig = {
  clientId: config.clientId,
  openIdCallback: config.openIdCallback,
  authority: config.authority,
  scopes: config.scopes,
  isGatewayScopeRequired: config.isGatewayScopeRequired,
  gatewayScopeType: config.gatewayScopeType,
  logLevel: config.logLevel,
  cacheLocation: config.cacheLocation,
};

const authProvider = new AuthProvider(authConfig);

export default authProvider;
