import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';

function SelectFilter(props) {
  const {
    optionList,
    maxWidth,
    minWidth,
    selected,
    name,
    error,
    id,
    helperText,
    isEmpty,
    disabled,
    getSelected,
  } = props;

  const empty = isEmpty || false;
  const widthMin = minWidth || '150px';
  const widthMax = maxWidth || '150px';
  const selectName = name || '';
  const err = error || false;

  const handleChange = (event) => {
    getSelected(event.target.value, name);
  };

  return (
    <FormControl sx={{ minWidth: widthMin, maxWidth: widthMax }} size='small'>
      <Select
        id={id}
        sx={{ height: '2.175rem' }}
        name={selectName}
        error={err}
        value={empty === true ? 0 : selected}
        size='small'
        onChange={handleChange}
        disabled={!!(disabled || empty)}
      >
        {empty === false ? (
          optionList.map((option) => (
            <MenuItem
              key={option.id}
              disabled={option.id === -1}
              value={option.id}
            >
              {option.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem key={0} disabled value={0}>
            No results found
          </MenuItem>
        )}
      </Select>
      <FormHelperText sx={{ color: '#d32f2f' }} id={selectName}>{helperText ?? ''}</FormHelperText>
    </FormControl>
  );
}

export default SelectFilter;
